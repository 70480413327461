import { getSortedData } from 'helpers/get-sorted-data';

import store from 'store';

import { createSlice } from '@reduxjs/toolkit';

export type ResourceLinkType = {
  id: string;
  name: string;
  url: string;
};

export type ResourceType = {
  resourceId: string;
  categoryId: string;
  prevItemId: string | null;
  nextItemId: string | null;
  resourceLink: ResourceLinkType;
};

export type ResourceCategoryType = {
  id: string;
  name: string;
  prevItemId: string | null;
  nextItemId: string | null;
  resources: Array<ResourceType>;
  actionType: string | null;
};

interface ResourcesState {
  resources: Array<ResourceCategoryType>;
}

const initialState: ResourcesState = {
  resources: [],
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResources: (
      state,
      { payload }: { payload: Array<ResourceCategoryType> },
    ) => {
      state.resources = getSortedData(payload, 'id')
        .map((resourceCategory: ResourceCategoryType) => ({
          ...resourceCategory,
          resources: getSortedData(resourceCategory.resources, 'resourceId'),
        }))
        .filter((category: ResourceCategoryType) => !category.actionType);
    },
  },
});
export const actions = resourcesSlice.actions;
export const setResources = (resources: Array<ResourceCategoryType>) =>
  store.dispatch(actions.setResources(resources));
export const resourcesReducer = resourcesSlice.reducer;
