export const PATH = {
  main: '/',
  privateMain: '/game',
  /* Prepare game */
  breakoutRoom: '/game/breakout-room',
  assignHosts: '/game/assign-hosts',
  teamWaiting: '/game/:id/team-waiting/:roomId',

  tournament: '/game/tournament',

  /* Gameplay pages - login */
  gameLogin: '/login/:type/game/:id',

  /* Checking game status for redirecting */
  viewGame: '/game/:id',
  viewGameWithRoom: '/game/:id/room/:roomId',

  /* Gameplay pages */
  mainStage: '/game/main-stage/:roomId',
  gameplay: '/game/gameplay/:roomId',
  scoreSummaryWaiting: '/game/waiting',
  scoreSummary: '/game/scoreSummary',
  congratulations: '/game/congratulations',
  adminPanel: '/game/:id/panel',
  gameHistory: '/game/history',
};

export const requestUrls = {
  refreshToken: 'auth/token',
  verifyToken: 'auth/token/verify',
  storageUrl: 'storage/files',
  graphqlUrl: 'graphql',
};
