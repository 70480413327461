import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { useAddPointsErrModal } from './hooks';
import { isNumber } from 'lodash';

import { useAppSelector } from 'store';
import {
  GameTeam,
  TeamPlaying,
  curRoundNumberSelector,
  lastQuestionForMatchupSelector,
  roundFinishedSelector,
} from 'store/gameplaySlice';

import { ContainedButton, OutlinedButton } from 'components/Button';
import { TransparentInput } from 'components/Input';
import {
  Heading2,
  Heading3,
  Heading4,
  ResponsiveHeading4,
} from 'components/Typography';
import styled from 'styled-components';

import {
  addRoundPointToTeam,
  editRoundPointForTeam,
  getGamePlayingInfo,
} from 'api/gameplay';
import { addTeamMember } from 'api/team-waiting';

const TeamMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  min-width: 160px;
  width: 13vw;
  background-color: var(--black-60);
  padding: 12px 16px 5vh 16px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const MemberView = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2vh 0;

  > p {
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const RoundNumber = styled(Heading4)`
  position: absolute;
  top: 24px;
  right: -76px;
`;

const MemberItem = styled(ResponsiveHeading4)<{ disabled: boolean }>`
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const TotalPointItem = styled(Heading2)<{ disabled: boolean }>`
  cursor: default;
  position: relative;
  svg {
    display: none;
    cursor: pointer;
  }
  :hover {
    svg {
      display: ${({ disabled }) => (disabled ? 'none' : 'block')};
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
`;

type TeamItem = {
  team: 0 | 1;
  item: number;
  name?: string;
};

export const TeamView: FC<{
  team: GameTeam;
  position?: 'right' | 'left';
  playing?: null | boolean;
  teamPlay: TeamPlaying;
  onAddPoint?: (teamPlay: TeamPlaying) => void;
  point?: number;
  setEditingTeam?: (team: TeamPlaying) => void;
  member?: number;
  editing?: boolean;
  tmp?: number;
  children?: ReactNode;
}> = ({
  team,
  position = 'left',
  point,
  teamPlay,
  onAddPoint,
  editing,
  setEditingTeam,
  tmp,
  children,
}) => {
  const [editPoint, setEditPoint] = useState('0');
  const location = useLocation();
  const pointRef = useRef<any>();
  const isOnlyViewAccess = useMemo(() => !!(location.state as any)?.host, []);

  const [member, selectMember] = useState<number>();
  const roundFinished = useAppSelector(roundFinishedSelector).roundFinished;
  const [isEditing, setIsEditing] = useState(false);
  const gameId = useAppSelector((state) => state.game.game.id);

  const onSelectMember = (index: number) =>
    selectMember((prev) => (prev === index ? null : index));

  const isMainStage = location.pathname.startsWith('/game/main-stage');

  const teamPos: TeamPlaying = position === 'left' ? 0 : 1;

  const onEditPoint = (e: React.ChangeEvent<HTMLInputElement>) => {
    const point = e.target.value;
    const pointNum = Number(point);

    if (point === '' || Number(point[point.length - 1]) >= 0) {
      const newPoint = pointNum.toString() || '0';
      setEditPoint(newPoint);
      pointRef.current.value = newPoint;
    }
  };
  const onSubmitEditPoint = () => {
    editRoundPointForTeam(gameId, teamPos, Number(editPoint), () => {
      setEditingTeam(null);
    });
  };

  const onAddPlayer = () => {
    setIsEditing(!isEditing);
  };
  // const debounceEdit = debounce(onEditPoint, 300);

  useEffect(() => {
    if (isNumber(point)) {
      setEditPoint(String(point));
    }
  }, [point]);

  useEffect(() => {
    if (tmp) {
      // stop resetting player names when moving to next round
      // selectMember(null);
      setEditingTeam(null);
    }
  }, [tmp]);

  const handleAddRoundPoint = useCallback(
    () => onAddPoint(teamPlay),
    [onAddPoint, teamPlay],
  );

  return (
    <TeamMenu style={position === 'right' ? { right: 0 } : { left: 0 }}>
      <div>
        <Heading4 color='primary'>Team {team.name}</Heading4>
      </div>

      <div style={{ flex: 0.9 }}>
        <MemberView>
          {team.members.map((mem, index) => (
            <MemberItem
              key={index}
              color={member === index ? 'primary' : 'white'}
              onClick={() => !isOnlyViewAccess && onSelectMember(index)}
              disabled={isOnlyViewAccess}
            >
              #{index + 1} {mem.name}
            </MemberItem>
          ))}
        </MemberView>

        <AddPlayer
          gameId={gameId}
          team={team}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />

        <div>
          <Heading4>Total score</Heading4>
          {editing ? (
            <div style={{ position: 'relative' }}>
              <TransparentInput
                ref={pointRef}
                defaultValue={point}
                onChange={onEditPoint}
                onBlur={(e) => {
                  if (e.target.id.includes('edit-input')) {
                    return;
                  }
                  setEditingTeam(null);
                  setEditPoint(String(point));
                }}
                value={editPoint}
                autoFocus
                type='number'
                style={{ width: '100%' }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Escape') {
                    setEditingTeam(null);
                    setEditPoint(String(point));
                  }
                  if (e.key === 'Enter') {
                    onSubmitEditPoint();
                  }
                }}
                id='edit-input'
              />
              <Heading3
                color='success'
                onClick={onSubmitEditPoint}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  userSelect: 'none',
                  right: 0,
                  top: 4,
                  zIndex: 9,
                }}
              >
                Save
              </Heading3>
            </div>
          ) : (
            <TotalPointItem color='primary' disabled={isOnlyViewAccess}>
              {editPoint || '00'}
              <EditIcon onClick={() => setEditingTeam(teamPos)} />
            </TotalPointItem>
          )}
        </div>
      </div>

      {team?.members?.length !== 12 &&
        (!isEditing ? (
          <OutlinedButton
            size='sm'
            style={{
              marginTop: '0.5vh',
              width: '100%',
              height: 46,
            }}
            onClick={onAddPlayer}
            disabled={isOnlyViewAccess}
          >
            Add Player
          </OutlinedButton>
        ) : (
          <OutlinedButton
            size='sm'
            style={{
              marginTop: '1vh',
              width: '100%',
              height: 46,
              backgroundColor: 'var(--danger)',
            }}
            onClick={onAddPlayer}
            disabled={isOnlyViewAccess}
          >
            Cancel
          </OutlinedButton>
        ))}

      <div>
        {isMainStage || roundFinished ? null : (
          <OutlinedButton
            size='sm'
            style={{ marginTop: '1vh', width: '100%', height: 46 }}
            onClick={handleAddRoundPoint}
            disabled={isOnlyViewAccess}
          >
            Add Round Points
          </OutlinedButton>
        )}
      </div>
      {children}
    </TeamMenu>
  );
};

export const PlayingTeamDrawer = (props: {
  tmp?: number;
  roundPoint: number;
}) => {
  const { roomId } = useParams<{ roomId: string }>();
  const navigate = useNavigate();
  // for adding points multiplier in last question of matchups
  const lastQuestionForMatchup = useAppSelector(lastQuestionForMatchupSelector);
  const pointsMultiplier = useAppSelector(
    (state) => state.gameplay.game.pointsMultiplier,
  );
  const teamA = useAppSelector((state) => state.gameplay.teamA);
  const teamB = useAppSelector((state) => state.gameplay.teamB);
  const pointA = useAppSelector((state) => state.gameplay.pointA);
  const pointB = useAppSelector((state) => state.gameplay.pointB);
  const round = useAppSelector(curRoundNumberSelector);
  const gameId = useAppSelector((state) => state.game.game.id);
  // const room = useAppSelector((state) => state.game.gameRoom);
  // const role = useAppSelector((state) => state.auth.role);
  // const isGameLeader = useMemo(
  //   () => role === AdaptedRoles[roles.GAMELEADER],
  //   [role],
  // );
  const location = useLocation();
  const isOnlyViewAccess = useMemo(() => !!(location.state as any)?.host, []);
  const { renderAddPointsFailModal, openModal } = useAddPointsErrModal();

  const [editingTeam, setEditingTeam] = useState<TeamPlaying | null>(null);

  const handleAddRoundPoint = useCallback(
    (teamPlay: TeamPlaying) => {
      if (!props.roundPoint)
        return openModal(
          "You can't add round points if no answers have been revealed.",
        );

      addRoundPointToTeam({
        gameId,
        roomId,
        teamPlay,
        pointsMultiplier: lastQuestionForMatchup ? pointsMultiplier : null,
      });
    },
    [
      lastQuestionForMatchup,
      pointsMultiplier,
      roomId,
      gameId,
      props.roundPoint,
      openModal,
    ],
  );

  return (
    <>
      <TeamView
        team={teamA}
        position='left'
        point={pointA}
        teamPlay={0}
        onAddPoint={handleAddRoundPoint}
        editing={editingTeam === 0}
        setEditingTeam={setEditingTeam}
        tmp={props.tmp}
      >
        <RoundNumber>Round {round}</RoundNumber>

        {isOnlyViewAccess && (
          <ContainedButton
            size='md'
            containedColor='danger'
            onClick={() => navigate(`/game/${gameId}/panel`)}
            style={{ position: 'absolute', right: -254, top: 10 }}
          >
            Exit
          </ContainedButton>
        )}
      </TeamView>
      <TeamView
        team={teamB}
        position='right'
        point={pointB}
        teamPlay={1}
        onAddPoint={handleAddRoundPoint}
        editing={editingTeam === 1}
        setEditingTeam={setEditingTeam}
        tmp={props.tmp}
      />
      {renderAddPointsFailModal()}
    </>
  );
};

const TeamRow = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  .action {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 4;
  }
  input {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    height: 22px;
    width: 100%;
    padding-right: 48px;
    ::placeholder {
      color: var(--white-40);
    }
  }
`;

// write a functinal component called addPlayer

const AddPlayer = ({ gameId, team, isEditing, setIsEditing }) => {
  const { roomId } = useParams();
  const [editItem, setEditItem] = useState<TeamItem>(null);
  const onSubmitName = () => {
    if (editItem.name) {
      const newMemberIndex =
        [...team.members].reduce(
          (res, curr) => (curr.index < res ? res : curr.index),
          -1,
        ) + 1;

      addTeamMember(team.id, editItem.name, newMemberIndex, fetchTeamMembers);
    }
  };

  const fetchTeamMembers = () => {
    setEditItem((prev) => ({
      ...prev,
      name: '',
    }));
    setIsEditing(false);
    getGamePlayingInfo({ eventId: gameId, roomId });
  };

  return (
    <div
      style={{
        flexGrow: 1,
        overflow: 'auto',
        marginBottom: 16,
      }}
    >
      <TeamRow>
        {isEditing && (
          <input
            placeholder={'Player Name'}
            value={editItem?.name}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                onSubmitName();
              }
            }}
            onChange={(event) =>
              setEditItem((prev) => ({
                ...prev,
                name: event.target.value,
              }))
            }
            autoFocus
          />
        )}

        {isEditing && (
          <Heading4 color='primary' className='action' onClick={onSubmitName}>
            ADD
          </Heading4>
        )}
      </TeamRow>
    </div>
  );
};
