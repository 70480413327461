import axios, { AxiosResponse } from 'axios';

import { toast } from 'react-toastify';

export interface GameTeam {
  id: string;
  index: number;
  code: string;
  name: string;
  isExpired: boolean;
  members: { id: number; name: string; index: number; createdAt: string }[];
}

export const getTeamMembers = (
  { eventId, roomId }: { eventId: string; roomId: string },
  callback: (data: GameTeam[]) => void,
) => {
  axios
    .get(`/api/game-teams/host`, {
      params: {
        eventId,
        roomId,
        page: 1,
        limit: 999,
      },
    })
    .then((res: AxiosResponse<{ data: GameTeam[] }>) => {
      callback?.(res.data.data);
    })
    .catch((err) => {
      console.log('getTeamMembers err', err);
    });
};

export const editTeamMember = async (
  memberId: number,
  newName: string,
  callback?: () => void,
) => {
  await axios
    .patch(`/api/game-team-members/${memberId}`, {
      name: newName,
    })
    .then(() => {
      // toast.success('Member name Edited!');
      callback?.();
    })
    .catch((err) => {
      console.log('editTeamMember err', err);
      toast.error('Edit member name failed!');
    });
};

export const addTeamMember = async (
  teamId: string,
  name: string,
  index: number,
  callback?: () => void,
) => {
  await axios
    .post(`/api/game-team-members`, {
      teamId,
      name,
      index,
    })
    .then(() => {
      // toast.success('Member name Added!');
      callback?.();
    })
    .catch((err) => {
      console.log('createTeamMember err', err);
      toast.error('Add member name failed!');
    });
};

export const startGameApi = (roomId: string, callback: () => void) => {
  axios
    .post(`/api/gameplay/start-game`, { roomId })
    .then(callback)
    .catch((err) => {
      console.log('startGameApi err', err);
      toast.error('Start game failed!');
    });
};
