import { Config } from 'config';
import { Client, ClientOptions, createClient } from 'graphql-ws';

import { requestUrls } from './constant';

import { verifyToken } from 'api/auth';

const wsInstance = (function () {
  let instance: Client;
  function createInstance(options: ClientOptions): Client {
    const client = createClient({
      ...options,
      on: {
        ...options.on,
        opened: (socket: any) => {
          options.on?.opened?.(socket);
        },
      },
      retryAttempts: Infinity,
      shouldRetry: (ev: any) => (ev.code === 4499 ? false : true),
    });

    return client;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance({
          url: `${Config.REACT_APP_WEB_SOCKET_URL}${requestUrls.graphqlUrl}`,
          connectionParams: async () => {
            let token = getAccessToken();
            await verifyToken(token, async () => {
              // if current access token is expired
              // Access Token gets updated in
              // instance.interceptors.response
              // and stored in local storage
              // else use current access token

              token = getAccessToken();
            });

            return {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            };
          },
        });
      }
      return instance;
    },
  };
})();

export const getAccessToken = () =>
  JSON.parse(JSON.parse(sessionStorage.getItem('persist:root')).auth).token;

export default wsInstance;
