import { useCallback, useMemo, useRef, useState } from 'react';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-icon.svg';

import useOnClickOutside from 'hooks/useClickOutside';

import styled from 'styled-components';

export type SelectItemType = {
  label: string;
  value: string;
};

type PropsType = {
  value: string | null;
  setValue: (newValue: string | null) => void;
  options: Array<SelectItemType>;
  disabled?: boolean;
};

const Select: React.FC<PropsType> = ({
  value,
  setValue,
  options,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const handleToggleDropdown = useCallback(
    () => !disabled && setIsOpen((prev) => !prev),
    [disabled],
  );

  const selectedHost = useMemo(
    () => options.find((item) => item.value === value)?.label || '',
    [options, value],
  );

  const handleClearHost = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setValue(null);
      setIsOpen(false);
    },
    [setValue],
  );

  return (
    <SelectWrapper ref={ref}>
      <SelectHeader onClick={handleToggleDropdown} disabled={disabled}>
        <SelectHeaderValue>{selectedHost}</SelectHeaderValue>
        <ChevronIconWrap isOpen={isOpen}>
          <ChevronIcon />
        </ChevronIconWrap>
      </SelectHeader>
      <SelectDropdown isOpen={isOpen}>
        <ListItem>
          {options.length ? (
            options.map((option) => {
              const handleChangeValue = () => {
                value !== option.value && setValue(option.value);
                setIsOpen(false);
              };

              return (
                <Item
                  key={option.value}
                  isActive={value === option.value}
                  onClick={handleChangeValue}
                >
                  <span>{option.label}</span>
                  <ClearButton
                    type='button'
                    onClick={handleClearHost}
                    isActive={value === option.value}
                  >
                    X
                  </ClearButton>
                </Item>
              );
            })
          ) : (
            <p>No Options</p>
          )}
        </ListItem>
      </SelectDropdown>
    </SelectWrapper>
  );
};

export default Select;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const SelectHeader = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const SelectHeaderValue = styled.div`
  flex-grow: 1;
  padding: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 1.75rem;
  box-sizing: border-box;
`;

const ChevronIconWrap = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    display: block;
    transform: rotateX(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
    transition: transform 0.15s ease-in-out;
  }
`;

const SelectDropdown = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% + 0.25rem));
  max-height: 192px;
  overflow: auto;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: #0c2d74;
  box-shadow: 0px 4px 4px 0px rgba(53, 53, 53, 0.24);
  z-index: 2;
  box-sizing: border-box;
`;

const ListItem = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 0.75rem;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 0.25rem;

  & > p {
    width: 100%;
    text-align: center;
  }
`;

const Item = styled.li<{ isActive: boolean }>`
  position: relative;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 0.15)' : 'transparent'};
  font-size: 1rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  box-sizing: border-box;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  & > span {
    display: block;
    padding: 0.5rem 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const ClearButton = styled.button<{ isActive: boolean }>`
  position: relative;
  padding: 0.5rem 0.75rem;
  border: none;
  outline: none;
  font-size: 1rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  box-sizing: border-box;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: transparent;
  font-weight: 600;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;
