import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetTournamentData } from './hooks';
import { PATH } from 'helpers/constant';
import {
  copyToClipboard,
  injectDragToScroll,
  useCountDown,
} from 'helpers/utils';

import { useAppSelector } from 'store';
import { AdaptedRoles } from 'store/authSlice';
import { EndGameStatus, GamePlaying, GameStatus } from 'store/gameplaySlice';

import { BackButton, ContainedButton, OutlinedButton } from 'components/Button';
import { PageLayout, PageNavBar } from 'components/Layout';
import { Heading2, Heading4, Heading5 } from 'components/Typography';
import { roles } from 'components/assign-hosts-room/AssignHostsRoom';
import { PlayoffBracket } from 'components/playoff-bracket/PlayoffBracket';
import styled from 'styled-components';

import { createCountDownTime } from 'api/breakout-room';
import { updateEndGameStatus } from 'api/game';
import { revealMatchups, startNextMatchUpRound } from 'api/gameplay';

export const AdminPanelPage = () => {
  const { id } = useParams();
  const { role } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const game = useAppSelector((state) => state.game.game);
  const { reveal: revealedTeams } = useAppSelector(
    (state) => state.playoffBracketResult,
  );

  const [gameTimer, setGameTimer] = useState(0);
  const totalTimeCD = useCountDown(gameTimer);
  const [showMatchups, setShowMatchups] = useState(true);

  const { matches, teamPhotoMissing } = useGetTournamentData();

  const gameDone = game.status === GameStatus.done;

  const isAllowRevealCard =
    (matches.length && matches.every((el) => el.winner)) || gameDone;

  const isShowContinueButton =
    isAllowRevealCard &&
    matches.every((el) => revealedTeams?.includes(el.index));

  useEffect(() => {
    injectDragToScroll('tournament');
  }, []);

  useEffect(() => {
    if (game?.id !== id) {
      navigate(`/game/${id}`);
    }
  }, [game, id]);

  useEffect(() => {
    if (id && game?.gameTimer) {
      createCountDownTime(
        {
          type: `${id}-totalTime`,
          gameId: id,
        },
        (data) => {
          const createdAt = data.createdAt;
          const diff =
            game.gameTimer * 60 * 1000 -
            (new Date().getTime() - new Date(createdAt).getTime());
          const minute = diff / 60 / 1000;

          if (minute > 0) {
            setGameTimer(minute);
          }
        },
      );
    }
  }, [id, game?.gameTimer]);

  const onContinue = () => {
    if (!gameDone) {
      revealMatchups(id, matches);
      startNextMatchUpRound();
      return;
    }

    if (game.statusEnd !== EndGameStatus.none) {
      return;
    }

    return updateEndGameStatus(game.id, EndGameStatus.champion, () =>
      navigate(PATH.congratulations),
    );
  };

  const navigateToGamePrepare = useCallback(() => {
    navigate(PATH.breakoutRoom, { state: { fromAdminPanel: true } });
  }, []);

  return (
    <PageLayout className='flex-between'>
      <ResultContainer
        style={
          showMatchups ? {} : { width: 0, paddingLeft: 0, paddingRight: 0 }
        }
      >
        <div className='flex-between'>
          <div>
            <Heading4>Total time</Heading4>
            <Heading2 style={{ color: 'var(--primary)', marginTop: 4 }}>
              {totalTimeCD.minCD}:{totalTimeCD.secCD}
            </Heading2>
          </div>
          <OutlinedButton
            size='md'
            onClick={navigateToGamePrepare}
            style={{
              width: 176,
              marginRight: 16,
              backgroundColor: teamPhotoMissing ? 'red' : '',
            }}
          >
            Team Info
            {/* {teamPhotoMissing ? 'Upload Photos' : 'View Photos'} */}
          </OutlinedButton>
        </div>
        <Line />
        <Heading4 style={{ marginBottom: 10 }}>All matchups</Heading4>
        {matches.length ? (
          matches.map((match) => <MatchItem key={match.id} item={match} />)
        ) : (
          <Heading5>None</Heading5>
        )}
      </ResultContainer>
      <GameMapContainer>
        <PageNavBar
          style={{ position: 'relative' }}
          LeftContent={
            <Heading2 style={{ paddingLeft: 10 }}>tournament</Heading2>
          }
          MenuContent={<div />}
        />
        <div
          id='tournament'
          style={{
            flexGrow: 1,
            height: 0,
            overflow: 'auto',
          }}
          className='flex-between'
        >
          <PlayoffBracket
            containerStyle={{
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
            }}
            allowClick={isAllowRevealCard}
          />
        </div>
        {isShowContinueButton && role === AdaptedRoles[roles.GAMELEADER] ? (
          <div
            style={{ textAlign: 'right', paddingRight: 60, paddingBottom: 30 }}
          >
            <ContainedButton onClick={onContinue}>
              {gameDone ? 'View results' : 'Start Next Matchup'}
            </ContainedButton>
          </div>
        ) : null}
        <BackButton
          style={{
            position: 'absolute',
            left: 0,
            top: 22,
            width: 50,
            height: 50,
            transform: showMatchups ? undefined : 'rotate(180deg)',
          }}
          onClick={() => setShowMatchups(!showMatchups)}
        />
      </GameMapContainer>
    </PageLayout>
  );
};

const MatchItem: FC<{
  item: GamePlaying;
}> = ({ item }) => {
  const navigate = useNavigate();
  const {
    id: roomId,
    game,
    teamA,
    teamB,
    host,
    rounds,
    winner,
    roundName,
    pointA,
    pointB,
  } = item;

  const hostName = host ? `${host?.firstName} ${host?.lastName}` : 'N/A';
  const questionsPerMatchup = useAppSelector(
    (state) => state.game.game?.questionsPerMatchup,
  );
  const disabledJoin = !host || !!winner;

  const renderStatus = () => {
    if (!host) {
      return <Heading5>Waiting</Heading5>;
    }

    if (winner) {
      return <Heading5 style={{ color: 'var(--success)' }}>Completed</Heading5>;
    }

    return <Heading5 style={{ color: 'var(--primary)' }}>Running</Heading5>;
  };

  const handleJoin = () => {
    if (!rounds.length) {
      return navigate(`/game/main-stage/${roomId}`, {
        state: {
          host: host.id,
        },
      });
    }

    return navigate(`/game/gameplay/${roomId}`, {
      state: {
        host: host.id,
      },
    });
  };

  const onCopyHostLink = () => {
    const hostCode = game?.codes?.find((code) => code.role === 'host')?.code;

    if (hostCode) {
      copyToClipboard(
        `${window.origin}/login/host/game/${game.id}?code=${hostCode}&roomId=${roomId}`,
      );
    }
  };

  return (
    <MatchItemContainer>
      <div className='flex-between' style={{ alignItems: 'flex-start' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <Heading5>Team {teamA?.name}</Heading5>
            <Heading5 style={{ color: 'var(--primary)', marginLeft: 3 }}>
              {pointA}
            </Heading5>
          </div>
          <div style={{ display: 'flex', margin: '16px 0' }}>
            <Heading5>Team {teamB?.name}</Heading5>
            <Heading5 style={{ color: 'var(--primary)', marginLeft: 3 }}>
              {pointB}
            </Heading5>
          </div>
        </div>
        <Heading4 style={{ marginBottom: 10 }}>{roundName}</Heading4>
      </div>
      <div className='flex-between'>
        <Heading5 style={{ color: 'var(--white-60)' }}>
          Host: {hostName}
        </Heading5>
        <OutlinedButton onClick={onCopyHostLink} size='sm'>
          Copy Host Link
        </OutlinedButton>
      </div>
      <Line style={{ margin: '16px 0' }} />
      <div className='flex-between'>
        <OutlinedButton size='sm' disabled={disabledJoin} onClick={handleJoin}>
          Join
        </OutlinedButton>
        <Heading5>
          Round {rounds.length}/{questionsPerMatchup}
        </Heading5>
        {renderStatus()}
      </div>
    </MatchItemContainer>
  );
};

const ResultContainer = styled.div`
  height: 100vh;
  width: 28.9%;
  max-width: 400px;
  overflow: hidden;
  padding: 20px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  transition: all 0.3s;
`;

const GameMapContainer = styled.div`
  position: relative;
  transition: all 0.3s;
  height: 100vh;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  #tournament {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: var(--white-10);
  margin: 10px 0;
`;

const MatchItemContainer = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: var(--white-10);
  margin-bottom: 10px;
`;
