import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameInfo } from 'api/game';

interface GameSlice {
  game: GameInfo | null;
  gameRoom: { isStart: number; id?: number };
  index: number;
  currentUserId?: string;
  showGameNotes: boolean;
}

const initialState: GameSlice = {
  game: null,
  gameRoom: { isStart: 0 },
  index: -1,
  showGameNotes: true,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameSlice: (state, action: PayloadAction<Partial<GameSlice>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    finishHostsAssignment: (state) => {
      state.game.isHostsAssigned = true;
    },
    closeGameNotes: (state) => {
      state.showGameNotes = false;
    },
    updateGameInfo: (state, action: PayloadAction<Partial<GameInfo>>) => {
      if (state.game) {
        state.game = {
          ...state.game,
          ...action.payload,
        };
      }
    },
    updateStoreGameEndStatus: (state, action: PayloadAction<number>) => {
      if (state.game) {
        state.game = {
          ...state.game,
          statusEnd: action.payload,
        };
      }
    },
    setIsBreakoutPopup: (state) => {
      if (state.game) {
        state.game.isBreakoutPopup = 1;
      }
    },
    resetGame: () => {
      return initialState;
    },
  },
});
export const {
  setGameSlice,
  finishHostsAssignment,
  updateGameInfo,
  updateStoreGameEndStatus,
  setIsBreakoutPopup,
  closeGameNotes,
} = gameSlice.actions;

export const gameReducer = gameSlice.reducer;
