import { useRef } from 'react';

import { ReactComponent as ExclamationIcon } from 'assets/icons/warning-circle.svg';

import { useGameNotes } from 'pages/game-login/hooks';

import { ComponentWrap, HeaderBtn } from 'components/resources/Resources';

type PropsType = {
  notes: string;
};

const GameNotes: React.FC<PropsType> = ({ notes }) => {
  const { renderGameNotesModal, openModal } = useGameNotes(notes);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ComponentWrap ref={ref}>
      <HeaderBtn type='button' onClick={openModal}>
        <span>GAME NOTES</span>
        <ExclamationIcon data-type='exclamation' />
      </HeaderBtn>
      {renderGameNotesModal()}
    </ComponentWrap>
  );
};

export default GameNotes;
