import { useCallback } from 'react';

import { SelectItemType } from 'components/Select';
import styled from 'styled-components';

type PropsType = {
  item: SelectItemType;
  onClickHandler: (newValue: string) => void;
  currentValue: number;
};

const Item: React.FC<PropsType> = ({ item, onClickHandler, currentValue }) => {
  const handleSelectOption = useCallback(
    () => onClickHandler(item.value),
    [item, onClickHandler],
  );

  return (
    <TeamItem
      isActive={currentValue === +item.value}
      onClick={handleSelectOption}
    >
      {item.label}
    </TeamItem>
  );
};

export default Item;

const TeamItem = styled.li<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  gap: 5rem;
  font-size: 0.8125rem;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0.5rem;
  background: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 0.15)' : 'transparent'};
`;
