import { useEffect } from 'react';

import { isNumber } from 'lodash';

import store, { useAppSelector } from 'store';

import {
  BodyText3,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from 'components/Typography';

import { TeamItem } from './PlayoffBracket';
import {
  revealWinnerResult,
  setDefaultDisplayTeams,
  setDisplayTeam,
} from './playoffBracketSlice';

export const usePlayoffBracket = (allowClick: boolean, large?: 'large') => {
  const teamNumber = useAppSelector((state) => state.game.game.teamNumber);
  // const game = useAppSelector((state) => state.game.game);
  const { bracket, roomId } = useAppSelector((state) => state.playoffBracket);
  const { reveal: revealedTeams, display: displayTeams } = useAppSelector(
    (state) => state.playoffBracketResult,
  );
  useEffect(() => {
    if (displayTeams?.length === 0) {
      store.dispatch(setDefaultDisplayTeams(teamNumber / 2));
    }
  }, [displayTeams, teamNumber]);

  const renderTeamsByRoom = (
    roomIndex: number,
    [winIndex, loseIndex]: number[],
    small?: boolean,
  ) => {
    const matchup = bracket.find((el) => el.roomIndex === roomIndex);
    const hosted = matchup?.room?.host ? true : false;
    const roomFinished = matchup?.room?.winner ? true : false;

    const roomFinishedAndWaitNext =
      matchup?.room?.winner &&
      !bracket.find((roomInfo) => {
        return (
          roomInfo.room?.round > matchup?.room?.round && roomInfo.room?.isStart
        );
      })
        ? true
        : false;
    const selected = isNumber(roomId) && matchup?.room?.id === roomId;
    const bothTeamsJoined =
      matchup?.room?.teamA && matchup?.room?.teamB ? true : false;

    const TeamIndexText = large && !small ? Heading4 : BodyText3;
    const TeamNameText = large && !small ? Heading3 : Heading5;
    const TeamPointText = large && !small ? Heading2 : Heading3;

    const renderTeamItem = (teamPosition: 0 | 1) => {
      const teamIndex = matchup ? matchup.teams[teamPosition].matchId : -1;
      const otherTeamIndex = matchup
        ? matchup.teams[teamPosition === 0 ? 1 : 0].matchId
        : -1;

      const revealed = (revealedTeams || []).includes(roomIndex);

      const nextWinMatch = displayTeams.find((el) => el.roomIndex == winIndex);
      const nextLoseMatch = displayTeams.find(
        (el) => el.roomIndex == loseIndex,
      );

      const nextMatchup = bracket.find((el) => el.roomIndex === winIndex)?.room
        ?.isStart;
      const isWinner =
        matchup?.room && matchup.room.winner?.index === teamIndex;
      // const isNeedHighlighted =
      //   !bracket.find(
      //     (roomInfo) => roomInfo.room?.round > matchup?.room?.round,
      //   ) ||
      //   !!bracket.find(
      //     (roomInfo) =>
      //       roomInfo.room?.round > matchup?.room?.round &&
      //       roomInfo.room?.isStart,
      //   );

      // const highlight = isWinner && revealed && isNeedHighlighted;
      const highlight = isWinner && revealed && !nextMatchup;

      const isDisplayed = displayTeams?.some(
        (el) => el.roomIndex === roomIndex && el.teamIndex?.includes(teamIndex),
      );

      const teamName = matchup ? matchup.teams[teamPosition].teamName : '';
      const teamIndexText = teamName ? `Team ${teamIndex + 1}` : '';
      const teamPoint =
        !matchup || !roomFinished ? '' : matchup.teams[teamPosition].point;

      if (revealed) {
        if (
          isWinner &&
          (!nextWinMatch ||
            nextWinMatch.teamIndex.includes(teamIndex) === false)
        ) {
          store.dispatch(
            setDisplayTeam({
              roomIndex: winIndex,
              teamIndex,
            }),
          );
        }
        if (
          !isWinner &&
          (!nextLoseMatch ||
            nextLoseMatch.teamIndex.includes(teamIndex) === false)
        ) {
          store.dispatch(
            setDisplayTeam({
              roomIndex: loseIndex,
              teamIndex,
            }),
          );
        }
      }

      return (
        <TeamItem
          top={teamPosition === 0}
          onClick={() => {
            if (allowClick && roomFinished && !revealed) {
              store.dispatch(revealWinnerResult(roomIndex));
              store.dispatch(
                setDisplayTeam({
                  roomIndex: winIndex,
                  teamIndex: isWinner ? teamIndex : otherTeamIndex,
                }),
              );
              store.dispatch(
                setDisplayTeam({
                  roomIndex: loseIndex,
                  teamIndex: isWinner ? otherTeamIndex : teamIndex,
                }),
              );
              // });
              // store.dispatch(revealWinnerResult(roomIndex));
              // store.dispatch(
              //   setDisplayTeam({
              //     roomIndex: winIndex,
              //     teamIndex: isWinner ? teamIndex : otherTeamIndex,
              //   }),
              // );
              // store.dispatch(
              //   setDisplayTeam({
              //     roomIndex: loseIndex,
              //     teamIndex: isWinner ? otherTeamIndex : teamIndex,
              //   }),
              // );
            }
          }}
          hightlight={highlight}
          selected={selected}
          clickable={allowClick && roomFinished && !revealed}
          finished={roomFinishedAndWaitNext}
          // finished={roomFinished}
          hosted={hosted && bothTeamsJoined}
        >
          <div>
            <TeamIndexText
              color='white-60'
              style={{
                textTransform: 'uppercase',
                opacity: isDisplayed ? 1 : 0,
              }}
            >
              {teamIndexText}
            </TeamIndexText>
            <TeamNameText style={{ opacity: isDisplayed ? 1 : 0 }}>
              {teamName}
            </TeamNameText>
          </div>
          <TeamPointText
            color={isWinner ? 'white' : 'primary'}
            className='score'
            style={{ opacity: revealed ? 1 : 0 }}
          >
            {teamPoint}
          </TeamPointText>
        </TeamItem>
      );
    };

    return (
      <>
        {renderTeamItem(0)}
        {renderTeamItem(1)}
      </>
    );
  };

  return {
    renderTeamsByRoom,
  };
};
