import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'helpers/constant';
import { injectDragToScroll } from 'helpers/utils';
import { useGetHostsTournamentData } from 'pages/hooks';

import { useAppSelector } from 'store';
import { AdaptedRoles } from 'store/authSlice';
import { EndGameStatus, GameStatus } from 'store/gameplaySlice';

import { PageContent, PageLayout, PageNavBar } from 'components/Layout';
import { Heading2 } from 'components/Typography';
import { roles } from 'components/assign-hosts-room/AssignHostsRoom';
import { PlayoffBracket } from 'components/playoff-bracket/PlayoffBracket';
import { ContinueButton } from 'pages/game-prepare/BreakoutRoomPage';
import styled from 'styled-components';

import { updateGameStatus } from 'api/game';

export const TournamentPage: FC<{ scoreSummary?: boolean }> = ({
  scoreSummary,
}) => {
  const navigate = useNavigate();
  const gameId = useAppSelector((state) => state.game.game.id);
  const status = useAppSelector((state) => state.game.game.status);
  const statusEnd = useAppSelector((state) => state.game.game.statusEnd);
  const company = useAppSelector((state) => state.game.game.customer?.company);
  const role = useAppSelector((state) => state.auth.role);
  const isGameLeader = useMemo(
    () => role === AdaptedRoles[roles.GAMELEADER],
    [role],
  );
  const isGameDone = useMemo(() => status === GameStatus.done, [status]);

  const showContinueBtn = useMemo(
    () => isGameLeader && !scoreSummary,
    [isGameLeader, scoreSummary],
  );

  useEffect(() => {
    injectDragToScroll('tournament');
  }, []);

  useEffect(() => {
    if (isGameDone && statusEnd !== EndGameStatus.none) {
      navigate(PATH.congratulations);
    }
  }, [isGameDone, statusEnd]);

  useGetHostsTournamentData();

  const navigateToAdminPanel = useCallback(() => {
    setTimeout(() => {
      navigate(`/game/${gameId}/panel`);
    }, 1000);
  }, [gameId]);

  const onContinue = useCallback(() => {
    if (!isGameLeader) {
      return;
    }
    if (status === GameStatus.created) {
      updateGameStatus(gameId, GameStatus.playing, () => {
        navigateToAdminPanel();
      });
      return;
    }

    navigateToAdminPanel();
  }, [isGameLeader, status, updateGameStatus, navigateToAdminPanel]);

  return (
    <PageLayout>
      <PageNavBar
        LeftContent={
          <Heading2>
            {company ? `The ${company} Tournament` : 'Tournament'}
          </Heading2>
        }
        MenuContent={<div />}
      />
      <div style={{ height: 96 }} />
      <Container id='tournament'>
        <PlayoffBracket
          containerStyle={{ paddingLeft: 20, paddingRight: 20 }}
        />
      </Container>

      {showContinueBtn && (
        <ContinueButton onClick={onContinue}>Continue</ContinueButton>
      )}
    </PageLayout>
  );
};

const Container = styled(PageContent)`
  max-height: 70vh;
  overflow: auto;
  padding-top: 42px;
  display: block;
  max-width: none;
  max-height: 80vh;

  ::-webkit-scrollbar {
    display: none;
  }
`;
