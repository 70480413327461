import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import store, { useAppSelector } from 'store';
import { setGameSlice } from 'store/gameSlice';
import { setResources } from 'store/resources';

import { getDetailGame, getResources } from 'api/game';

const PrivateLayout: React.FC = () => {
  const gameId = useAppSelector((state) => state.auth.gameId);
  const token = useAppSelector((state) => state.auth.token);
  const resources = useAppSelector((state) => state.resources.resources);

  useEffect(() => {
    getDetailGame(gameId, (data) => {
      store.dispatch(setGameSlice({ game: data, index: -1 }));
    });
  }, []);

  useEffect(() => {
    if (token && !resources.length) {
      getResources({
        callback: (resources) => setResources(resources),
      });
    }
  }, [token, resources]);

  return <Outlet />;
};

export default PrivateLayout;
