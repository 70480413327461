import { FC, ReactNode } from 'react';

import LogoIcon from 'assets/icons/logo-icon.png';
import BackgroundImage from 'assets/images/background.png';

import styled, { CSSProperties } from 'styled-components';

export const PageLayout = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
`;

export const PageContent = styled.div`
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  max-width: 1120px;
  margin: auto;
`;

const Navbar = styled.div`
  position: fixed;
  width: 100%;
  height: 96px;
  background: var(--black-20);
  display: flex;
  align-items: center;

  > div {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:last-child > svg {
    display: none;
  }
`;

export const PageNavBar: FC<{
  LeftContent: ReactNode;
  MenuContent: ReactNode;
  style?: CSSProperties;
}> = ({ LeftContent, MenuContent, style }) => {
  return (
    <Navbar style={style}>
      <div>
        {LeftContent}

        <NavMenu>{MenuContent}</NavMenu>

        <img src={LogoIcon} alt='logo' width={120} height={48} />
      </div>
    </Navbar>
  );
};
