import styled from 'styled-components';

export type ThemeColor =
  | 'dark'
  | 'primary'
  | 'success'
  | 'danger'
  | 'gray'
  | 'sky'
  | 'orange'
  | 'black-20'
  | 'black-60'
  | 'black-80'
  | 'white'
  | 'white-10'
  | 'white-40'
  | 'white-60';

const Typograpy = styled.p<{
  color?: ThemeColor;
  font?: 'fugaz-one' | 'inter';
}>`
  color: var(--${({ color = 'white' }) => color});
  font-family: ${({ font = 'fugaz-one' }) => font};
`;

export const Display1 = styled(Typograpy)`
  font-size: 64px;
  line-height: 80px;
  text-transform: uppercase;
`;

export const Display2 = styled(Typograpy)`
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase;
`;

export const Display3 = styled(Typograpy)`
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
`;

export const Heading1 = styled(Typograpy)`
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Heading2 = styled(Typograpy)`
  font-size: 24px;
  text-transform: uppercase;
`;

export const Heading3 = styled(Typograpy)`
  font-size: 20px;
  text-transform: uppercase;
`;

export const Heading4 = styled(Typograpy)`
  font-size: 16px;
  text-transform: uppercase;
`;

export const ResponsiveHeading4 = styled(Typograpy)`
  font-size: calc(1vh + 0.5vw);
  text-transform: uppercase;
`;

export const Heading5 = styled(Typograpy)`
  font-size: 13px;
  text-transform: uppercase;
`;

export const BodyText = styled(Typograpy)`
  font-size: 16px;
  font-family: inter;
`;

export const BodyText2 = styled(Typograpy)`
  font-size: 13px;
  font-family: inter;
`;

export const BodyText3 = styled(Typograpy)`
  font-size: 10px;
`;
