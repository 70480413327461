import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGameDone } from './hooks';
import { PATH } from 'helpers/constant';

import { useAppSelector } from 'store';
import { AdaptedRoles } from 'store/authSlice';
import { GameStatus } from 'store/gameplaySlice';

import { PageLayout } from 'components/Layout';
import { Heading2 } from 'components/Typography';
import { roles } from 'components/assign-hosts-room/AssignHostsRoom';

import { getDetailGameForHost } from 'api/game';
import { getStartedRoom } from 'api/gameplay';

export const ViewGamePage = () => {
  const { id, roomId } = useParams();
  const navigate = useNavigate();
  const role = useAppSelector((state) => state.auth.role);
  const game = useAppSelector((state) => state.game.game);
  const { renderGameDoneModal, openModal } = useGameDone(game?.id, role);
  const {
    renderGameDoneModal: renderGameDoneModalNotStarted,
    openModal: openModalNotStarted,
  } = useGameDone(game?.id, role);
  const [firstLoad, setFirstLoad] = useState(false);
  const [currentRoom, setCurrentRoom] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      let expectedRole = '';

      switch (role) {
        case AdaptedRoles[roles.GAMELEADER]:
          expectedRole = roles.GAMELEADER;
          break;
        case AdaptedRoles[roles.HOST]:
          expectedRole = roles.HOST;
          break;
        case AdaptedRoles[roles.CO_HOST]:
          expectedRole = roles.CO_HOST;
          break;
        default:
          break;
      }

      const rejectCallback = () =>
        navigate(`/login/${expectedRole}/game/${id}`);

      getDetailGameForHost(
        { eventId: id, roomId },
        (id?: string) => {
          id && expectedRole === roles.HOST && setCurrentRoom(id);
          setFirstLoad(true);
        },
        () => rejectCallback(),
      );
    }
  }, [id]);

  useEffect(() => {
    if (game && firstLoad) {
      if (game.notStarted) {
        return openModalNotStarted();
      }
      const gameStatus = game.status;
      switch (gameStatus) {
        case GameStatus.created: {
          return navigate(
            game.isBreakout
              ? PATH.tournament
              : game.isHostsAssigned
              ? PATH.breakoutRoom
              : PATH.assignHosts,
          );
        }
        case GameStatus.playing: {
          if (!currentRoom) {
            navigate(`/game/${game.id}/panel`);
            return;
          }
          getStartedRoom(
            { eventId: game.id, roomId: currentRoom },
            (data) => {
              if (!data?.rounds?.length) {
                return navigate(
                  data?.round || data?.isStart
                    ? `/game/main-stage/${currentRoom}`
                    : `/game/${game.id}/team-waiting/${currentRoom}`,
                );
              }
              navigate(`/game/gameplay/${currentRoom}`);
            },
            () => navigate(PATH.scoreSummaryWaiting),
          );
          return;
        }
        case GameStatus.done: {
          openModal();
          break;
        }
      }
    }
  }, [role, firstLoad, currentRoom]);

  return (
    <PageLayout className='flex-between' style={{ justifyContent: 'center' }}>
      <Heading2>Loading...</Heading2>
      {renderGameDoneModal()}
      {renderGameDoneModalNotStarted(true)}
    </PageLayout>
  );
};
