import { RootState } from 'store';

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { HostItem, QuestionItem } from 'api/default-settings';

export type GameType = 'now' | 'later';

export enum GameStatus {
  created = 'upcoming',
  playing = 'ongoing',
  done = 'completed',
}

export enum EndGameStatus {
  none = 0,
  champion = 1,
  ranking = 2,
}

export interface GameTeam {
  id: string;
  code: string;
  index: number;
  isExpired: boolean;
  name: string;
  members: {
    id: number;
    name: string;
    index: number;
  }[];
  winner?: boolean;
  teamPhoto?: string;
}

export type TeamPlaying = null | 0 | 1;

export interface GameRoundInfo {
  id: number;
  pointA: number;
  pointB: number;
  strikes: number;
  question: QuestionItem;
  showAddRoundPoint?: boolean;
  answers: string;
  stackPoint: number;
}

export enum RoundStatus {
  ready,
  playing,
  done,
}

export interface EventData {
  id: string;
  codes: { code: string; role: string }[];
  answerTimer: number;
  breakoutTimer: number;
  code: string;
  expireTime: string;
  gameTimer: number;
  gameType: GameType;
  questionsPerMatchup: number;
  questionsTotal: number;
  startTime: string;
  status: GameStatus;
  teamNumber: number;
  timeZone: number;
  theme: { value: string };
  isStart: number;
  pointsMultiplier: number;
}

export interface GamePlaying {
  game: EventData;
  host: HostItem;
  coHost: HostItem;
  id: number;
  index: number;
  isConsolation: boolean;
  point: number;
  pointLoss: number;
  pointA: number;
  pointB: number;
  round: number;
  rounds: { id: number; stackPoint: number; status: RoundStatus }[];
  teamA: GameTeam;
  teamB: GameTeam;
  curRound: GameRoundInfo;
  isStart: number;
  isRevealed: boolean;
  winner?: {
    id: string;
    name: string;
    index: number;
  };
  roundName?: string;
}

const initialState: GamePlaying = {
  game: {
    codes: [],
    answerTimer: null,
    gameTimer: null,
    gameType: 'now',
    questionsPerMatchup: null,
    questionsTotal: null,
    teamNumber: null,
    timeZone: null,
    breakoutTimer: null,
    code: '',
    expireTime: '',
    id: null,
    startTime: '',
    status: GameStatus.created,
    theme: { value: '' },
    isStart: 0,
    pointsMultiplier: 1,
  },
  host: {
    avatar: '',
    email: '',
    firstName: '',
    id: null,
    lastName: '',
  },
  coHost: {
    avatar: '',
    email: '',
    firstName: '',
    id: null,
    lastName: '',
  },
  id: null,
  index: null,
  isConsolation: false,
  point: null,
  pointLoss: null,
  pointA: 0,
  pointB: 0,
  round: 0,
  rounds: [],
  teamA: {
    code: '',
    id: null,
    index: null,
    isExpired: false,
    members: [],
    name: '',
  },
  teamB: {
    code: '',
    id: null,
    index: null,
    isExpired: false,
    members: [],
    name: '',
  },
  isStart: 0,
  isRevealed: false,
  curRound: {
    id: null,
    pointA: null,
    pointB: null,
    strikes: null,
    stackPoint: 0,
    question: {
      answers: [],
      categoryId: null,
      content: '',
    },
    answers: '',
  },
};

const gameplaySlice = createSlice({
  name: 'gameplay',
  initialState,
  reducers: {
    updateGamePlaying: (state, action: PayloadAction<Partial<GamePlaying>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateGamePlayingAfterAnswerReveal: (
      state,
      { payload }: { payload: { answerId: string; point: number } },
    ) => {
      const curRound = state.curRound;

      const currAnswers = state.curRound.answers;
      const answers = !currAnswers ? [] : currAnswers.split(',');
      answers.push(payload.answerId.toString());

      const updatedData = {
        stackPoint: (curRound.stackPoint || 0) + payload.point,
        answers: answers.toString(),
      };

      return {
        ...state,
        curRound: {
          ...state.curRound,
          ...updatedData,
        },
        rounds: state.rounds.map((round) =>
          round.id === curRound.id
            ? {
                ...round,
                ...updatedData,
              }
            : round,
        ),
      };
    },
    setShowAddRoundPoint: (state) => {
      state.curRound.showAddRoundPoint = !state.curRound.showAddRoundPoint;
    },
    resetCurRoundAnswer: (state) => {
      state.curRound = {
        ...state.curRound,
        pointA: 0,
        pointB: 0,
        strikes: 0,
        question: {
          answers: [],
          categoryId: null,
          content: '',
        },
      };
    },
  },
});

export const {
  updateGamePlaying,
  updateGamePlayingAfterAnswerReveal,
  resetCurRoundAnswer,
} = gameplaySlice.actions;

export const gameplayReducer = gameplaySlice.reducer;

const gameInfoSelector = (state: RootState) => {
  return state.gameplay.game;
};
const gameRoundsSelector = (state: RootState) => state.gameplay.rounds;
export const strikeCountSelector = (state: RootState) =>
  state.gameplay.curRound.strikes;

export const curRoundNumberSelector = createSelector(
  gameRoundsSelector,
  (rounds) => rounds.length,
);
export const curRoundStatusSelector = createSelector(
  gameRoundsSelector,
  (rounds) => (rounds.length ? rounds[rounds.length - 1].status : undefined),
);

export const roundFinishedSelector = createSelector(
  gameInfoSelector,
  curRoundNumberSelector,
  curRoundStatusSelector,
  ({ questionsPerMatchup }, round, roundStatus) => {
    return {
      roundFinished: roundStatus === RoundStatus.done,
      matchUpFinished: round >= questionsPerMatchup,
    };
  },
);

export const lastQuestionForMatchupSelector = createSelector(
  gameInfoSelector,
  curRoundNumberSelector,
  curRoundStatusSelector,
  ({ questionsPerMatchup }, round) => round === questionsPerMatchup,
);
