import { useEffect } from 'react';

const useDocumentTitle = (role: string, company: string | undefined) => {
  useEffect(() => {
    const originalTitle = document.title;

    if (company)
      document.title = `${
        role === 'Host'
          ? role
          : role === 'GameLeader'
          ? 'Game Leader'
          : 'Co-Host'
      } - ${company}`;

    return () => {
      document.title = originalTitle;
    };
  }, [company]);
};

export default useDocumentTitle;
