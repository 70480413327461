import { CSSProperties, FC, useEffect } from 'react';

import { injectDragToScroll } from 'helpers/utils';

import { useAppSelector } from 'store';

import styled from 'styled-components';

import { EightTeamsBracket } from './EightTeamsBracket';
import { FourTeamsBracket } from './FourTeamsBracket';
import { SixteenTeamsBracket } from './SixteenTeamsBracket';
import { TwoTeamsBracket } from './TwoTeamsBracket';

export interface BracketProps {
  disableClick?: boolean;
  allowClick?: boolean;
}

export const TwoTeamsChampionBracket = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 10vh repeat(8, 34px) 10vh;
  grid-template-columns: minmax(32px, auto) 280px minmax(32px, auto);
  margin: auto;
`;

export const FourTeamsChampionBracket = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(14, 34px);
  grid-template-columns: minmax(32px, auto) repeat(2, 280px 36px) 280px minmax(
      32px,
      auto
    );
`;
export const EightTeamsChampionBracket = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(16, 24px);
  grid-template-columns: minmax(32px, auto) repeat(4, 200px 26px) 200px minmax(
      32px,
      auto
    );
`;

export const SixteenTeamsChampionBracket = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(24, 24px);
  grid-template-columns: minmax(32px, auto) repeat(6, 200px 26px) 200px minmax(
      32px,
      auto
    );
`;

export const GridSpan = styled.div<{ span?: number }>`
  grid-row: span ${({ span = 1 }) => span};
`;

export type BrackLineProps = {
  highlight?: boolean;
  selected?: boolean;
  down?: boolean;
  span?: number;
};
export const BracketLine = styled.div<BrackLineProps>`
  grid-row: span ${({ span = 2 }) => span};

  ::before,
  ::after {
    width: calc(50% + 2px);
    height: calc(100% + 2px);
    box-sizing: border-box;
    display: inline-block;
    margin: -1px;
    border: 0 solid;
    border-color: var(
      --${({ highlight, selected }) => (highlight ? 'primary' : selected ? 'success' : 'white-40')}
    );
  }
  &.down::before {
    content: '';
    border-width: 2px 2px 0 0;
    border-bottom: 2px solid transparent;
  }
  &.down::after {
    content: '';
    border-bottom-width: 2px;
    border-top: 2px solid transparent;
  }
  &.up::before {
    content: '';
    border-width: 0 2px 2px 0;
    border-top: 2px solid transparent;
  }
  &.up::after {
    content: '';
    border-top-width: 2px;
    border-bottom: 2px solid transparent;
  }
`;

export const TeamItem = styled.div<{
  top?: boolean;
  round?: boolean;
  hightlight?: boolean;
  selected?: boolean;
  center?: boolean;
  clickable?: boolean;
  hosted?: boolean;
  finished?: boolean;
}>`
  grid-row: span 2;
  width: 100%;
  padding: 8px 16px;
  height: calc(100% ${({ top }) => (top ? '+ 1' : '- 1')}px);
  background: ${({ hightlight }) =>
    hightlight ? 'var(--success)' : 'var(--white-10)'};
  ${({ top }) =>
    top
      ? `border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-shadow: inset 0 -2px 0 rgba(255, 255, 255, 0.1);
    `
      : `border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;`};

  ${({ round }) => (round ? 'border-radius: 12px' : '')};
  box-sizing: border-box;
  display: flex;
  align-items: center;

  ${({ center }) =>
    center
      ? `
    justify-content: center;
    p {
      text-align: center;
    }
  `
      : `justify-content: space-between;`};

  ${({ selected, hosted, finished }) =>
    finished
      ? 'box-shadow: inset 0 0 0 2px var(--success)'
      : hosted
      ? 'box-shadow: inset 0 0 0 2px var(--white-40)'
      : selected
      ? 'box-shadow: inset 0 0 0 2px var(--primary)'
      : ''};

  ${({ clickable }) => (clickable ? `:hover { cursor: pointer; }` : '')};

  .score {
    line-height: 36px;
  }

  p {
    transition: opacity 0.5s;
  }
`;

const TreeContainer = styled.div`
  overflow: auto;
  padding: 46px 26px 0 26px;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const mapId = 'tournament-map';

export const PlayoffBracket: FC<{
  containerStyle?: CSSProperties;
  allowClick?: boolean;
}> = ({ containerStyle, allowClick = true }) => {
  useEffect(() => {
    injectDragToScroll(mapId);
  }, []);

  const gameType = useAppSelector((state) => state.game.game.teamNumber);

  return (
    <>
      <TreeContainer id={mapId} style={containerStyle}>
        {gameType === 2 && <TwoTeamsBracket allowClick={allowClick} />}
        {gameType === 4 && <FourTeamsBracket allowClick={allowClick} />}
        {gameType === 8 && <EightTeamsBracket allowClick={allowClick} />}
        {gameType === 16 && <SixteenTeamsBracket allowClick={allowClick} />}
      </TreeContainer>
    </>
  );
};
