import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as CheckedIcon } from 'assets/icons/green-tick-circle.svg';

import { PATH } from 'helpers/constant';
import { copyToClipboard, useCountDown } from 'helpers/utils';
import { createEmptyPlayers, useGetBreakoutData } from 'pages/hooks';

import store, { useAppSelector } from 'store';
import { AdaptedRoles } from 'store/authSlice';
import { setIsBreakoutPopup, updateGameInfo } from 'store/gameSlice';
import { GameStatus } from 'store/gameplaySlice';

import { ContainedButton, CopyButton } from 'components/Button';
import { PageContent, PageLayout, PageNavBar } from 'components/Layout';
import { useModal } from 'components/Modal';
import { Heading2, Heading3, Heading4 } from 'components/Typography';
import { roles } from 'components/assign-hosts-room/AssignHostsRoom';
import EditTeam, {
  DEFAULT_TEAMPLAYERS_NUMBER,
} from 'components/edit-team-popup/EditTeam';
import GameNotes from 'components/game-notes/GameNotes';
import { resetBracketReveal } from 'components/playoff-bracket/playoffBracketSlice';
import Resources from 'components/resources/Resources';
import styled from 'styled-components';

import {
  createCountDownTime,
  getEventCountDownTime,
  updateBreakoutGameStatus,
  updateBreakoutPopupStatus,
} from 'api/breakout-room';
import {
  createTeamForAdmin,
  getDetailGameForHost,
  updateGameStatus,
} from 'api/game';
import { getStartedRoom } from 'api/gameplay';

export interface TeamsListUpdate {
  index: number;
  name: string;
  teamPhoto: string | null;
  members: any[];
  initialMembers: any[];
}

export const BreakoutRoomPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const game = useAppSelector((state) => state.game.game);
  const role = useAppSelector((state) => state.auth.role);

  const {
    id,
    teamNumber,
    breakoutTimer = 0,
    status,
    isBreakoutPopup,
  } = game || {};
  const [countDownTime, setCountDownTime] = useState(0);
  const [editedTeam, setEditedTeam] = useState<any>(null);

  const { openModal, Modal, closeModal } = useModal();
  const {
    openModal: openEditTeamModal,
    closeModal: closeEditTeamModal,
    Modal: EditTeamModal,
    open: isOpenEditModal,
  } = useModal();
  const [updateTeamLoading, setUpdateTeamLoading] = useState(false);
  const { minCD, secCD } = useCountDown(countDownTime);
  const originalTeams = useRef<any[]>([]);
  const disabledSubmit = originalTeams.current.some(
    (el) => !el.id || !el.teamPhoto || !el.name,
  );
  const isGameLeader = useMemo(
    () => role === AdaptedRoles[roles.GAMELEADER],
    [role],
  );
  const isCoHost = useMemo(() => role === AdaptedRoles[roles.CO_HOST], [role]);
  const itvGetEventTotalTime = useRef<any>(null);

  // if user came from admin panel page, we allow him to update teams and show continue btn
  const allowAccess = location.state?.fromAdminPanel;

  useEffect(() => {
    store.dispatch(resetBracketReveal());
  }, []);

  useEffect(() => {
    if (isBreakoutPopup) {
      handleUpdateCountDown(id);
      closeModal();
    }
  }, [isBreakoutPopup, isGameLeader]);

  const handleCreateTeam = useCallback(
    (body: TeamsListUpdate) => {
      setUpdateTeamLoading(true);
      const dto = { ...body };
      dto.members = dto.members.filter((player: any) => !!player.name.trim());
      dto.initialMembers = dto.initialMembers.filter(
        (player: any) => !!player.name.trim(),
      );

      const { name, teamPhoto } = dto;

      createTeamForAdmin(
        id,
        dto,
        (resultMembersList) => {
          closeEditTeamModal();
          setUpdateTeamLoading(false);

          setTeams((prev) =>
            [...prev].map((team: any) =>
              team.index !== dto.index
                ? team
                : {
                    ...team,
                    name,
                    teamPhoto,
                    members: [
                      ...resultMembersList,
                      ...createEmptyPlayers(resultMembersList).slice(
                        0,
                        DEFAULT_TEAMPLAYERS_NUMBER - resultMembersList.length,
                      ),
                    ],
                  },
            ),
          );
        },
        () => {
          setUpdateTeamLoading(false);
        },
      );
    },
    [id, role],
  );
  console.log(teamNumber);
  const { teams, setTeams } = useGetBreakoutData(id, teamNumber);

  useEffect(() => {
    if (game?.status === GameStatus.playing && !allowAccess) {
      if (isCoHost) {
        navigate(`/game/${id}/panel`);
        return;
      }
      getDetailGameForHost(
        {
          eventId: id,
        },
        (roomId?: string) => {
          if (roomId) {
            getStartedRoom(
              { eventId: id, roomId },
              (data) => {
                if (!data?.rounds?.length) {
                  return navigate(
                    data?.isStart
                      ? `/game/main-stage/${roomId}`
                      : `/game/${id}/team-waiting/${roomId}`,
                  );
                }
                navigate(`/game/gameplay/${roomId}`);
              },
              () => navigate(PATH.scoreSummaryWaiting),
            );
            return;
          }
          navigate(`/game/${id}/panel`);
        },
      );
    }
  }, [game?.status]);

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    if (!isGameLeader) {
      const getTotalTime = () => {
        getEventCountDownTime(
          { gameId: id, type: `${id}-totalTime` },
          (data: any) => {
            data && closeModal();
            clearInterval(itvGetEventTotalTime.current);
          },
        );
      };

      itvGetEventTotalTime.current = setInterval(() => getTotalTime(), 5000);

      getTotalTime();
    }

    return () => clearInterval(itvGetEventTotalTime.current);
  }, []);

  const handleUpdateCountDown = (id: string) => {
    createCountDownTime(
      {
        type: `${id}-breakoutTimer`,
        gameId: id,
      },
      (data) => {
        const createdAt = data.createdAt;
        const diff =
          breakoutTimer * 60 * 1000 -
          (new Date().getTime() - new Date(createdAt).getTime());
        const minute = diff / 60 / 1000;

        if (minute > 0) {
          setCountDownTime(minute);
        }
      },
    );
  };

  const onOpenBreakoutRoom = () => {
    if (id && isGameLeader) {
      updateBreakoutPopupStatus(id, () => {
        store.dispatch(setIsBreakoutPopup());
      });
    }
  };

  const onCopyInstruction = () => {
    copyToClipboard(
      'BREAKOUT ROOM INSTRUCTIONS\n1. Take a team photo\n2. Choose a family name (AKA YOUR team name)\n3. Create a wild, wacky backstory for your family. How is everyone related?\n4. Nominate 1 family member to introduce your family and share your family backstory once we’re in the main room.',
    );
  };

  const onNavigateTournament = () => {
    updateBreakoutGameStatus(id, skipTournamentPage);
  };

  const skipTournamentPage = () => {
    if (isGameLeader && status === GameStatus.created) {
      updateGameStatus(id, GameStatus.playing, () => {
        updateGameInfo({ status: GameStatus.playing });
      });
    }
    navigate(`/game/${id}/panel`);
  };

  return (
    <PageLayout>
      <PageNavBar
        LeftContent={
          <Heading2>
            {allowAccess ? 'Team Information' : 'Breakout room'}
          </Heading2>
        }
        MenuContent={
          allowAccess ? (
            <></>
          ) : (
            <Heading2 color='primary'>
              {minCD}:{secCD}
            </Heading2>
          )
        }
      />

      <PageContent
        style={{ alignItems: 'flex-start', maxHeight: 'calc(100vh - 96px)' }}
      >
        <ConfigLine>
          <CopyButton size='sm' onClick={onCopyInstruction}>
            Breakout room instructions
          </CopyButton>
          <ConfigLineRightSide>
            {!!game?.notes && <GameNotes notes={game.notes} />}
            <Resources />
          </ConfigLineRightSide>
        </ConfigLine>

        {game && (
          <TeamNameInputForm rowsCount={teamNumber / 2}>
            {teams.map((_v, idx) => {
              const handleOpenEditTeamPopup = () => {
                setEditedTeam(_v);
                openEditTeamModal();
              };

              return (
                <div
                  key={idx}
                  className='flex-start'
                  style={{
                    padding: 16,
                    gap: '.5rem',
                    cursor: 'pointer',
                    background: '#2D73CC',
                    borderRadius: '1rem',
                  }}
                  onClick={handleOpenEditTeamPopup}
                >
                  <CheckedIconWrap isEmpty={!_v.teamPhoto}>
                    {_v.teamPhoto && <CheckedIcon />}
                  </CheckedIconWrap>
                  <Heading3
                    style={{
                      marginRight: 16,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {_v.name}
                  </Heading3>
                </div>
              );
            })}
          </TeamNameInputForm>
        )}
      </PageContent>

      {(isGameLeader || allowAccess) && (
        <ContinueButton
          onClick={onNavigateTournament}
          disabled={disabledSubmit}
        >
          Continue
        </ContinueButton>
      )}
      {!isBreakoutPopup && (
        <Modal
          type='custom'
          header=''
          style={{
            content: {
              width: 736,
              padding: 16,
              boxSizing: 'border-box',
            },
          }}
          onAfterClose={onOpenBreakoutRoom}
          shouldCloseOnOverlayClick={false}
        >
          {isGameLeader ? (
            <Heading2 color='primary'>Breakout room instructions</Heading2>
          ) : (
            <>
              <Heading2 color='primary' style={{ marginBottom: 20 }}>
                Waiting For Breakout Rooms To Open
              </Heading2>
              <Heading4 color='primary'>Breakout room instructions</Heading4>
            </>
          )}
          <InstructionContent>
            <Heading4>1. Take a team photo</Heading4>
            <Heading4>2. choose a family name (AKA YOUR team name)</Heading4>
            <Heading4>
              3. create a wild, wacky backstory for your family. how is <br />
              everyone related?
            </Heading4>
            <Heading4>
              4. nominate 1 family member to introduce your family and share
              <br /> your family backstory once we’re in the main room.
            </Heading4>

            {isGameLeader && (
              <ContainedButton
                size='md'
                style={{ width: 220, backgroundSize: '100% 48px' }}
                onClick={() => {
                  closeModal();

                  createCountDownTime({
                    type: `${id}-totalTime`,
                    gameId: id,
                  });
                }}
              >
                Open Breakout rooms
              </ContainedButton>
            )}
          </InstructionContent>
        </Modal>
      )}
      {isOpenEditModal && (
        <EditTeam
          isOpen={isOpenEditModal}
          closeModal={closeEditTeamModal}
          Modal={EditTeamModal}
          teamData={editedTeam}
          editTeam={handleCreateTeam}
          disabled={updateTeamLoading}
        />
      )}
    </PageLayout>
  );
};

const InstructionContent = styled.div`
  > * {
    margin-top: 24px;
  }
`;

const TeamNameInputForm = styled.div<{ rowsCount: number }>`
  display: grid;
  grid-template-rows: repeat(
    ${({ rowsCount }) => Math.max(rowsCount, 4)},
    55px
  );
  grid-template-columns: repeat(2, 352px);
  grid-row-gap: 16px;
  grid-column-gap: 32px;
  margin-top: 36px;
  grid-auto-flow: column;
  height: 100%;
  overflow: auto;
`;

const CheckedIconWrap = styled.div<{ isEmpty: boolean }>`
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background: ${({ isEmpty }) => (isEmpty ? '#1A4A8E' : 'transparent')};

  & > svg > path {
    fill: #3aff7d;
  }
`;

export const ContinueButton = styled(ContainedButton)`
  position: fixed;
  bottom: 48px;
  right: 160px;
`;

const ConfigLine = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ConfigLineRightSide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
