import store from 'store';

import { createSlice } from '@reduxjs/toolkit';

interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    openLoading: (state) => {
      state.isLoading = true;
    },
    closeLoading: (state) => {
      state.isLoading = false;
    },
  },
});
export const actions = loadingSlice.actions;
export const openLoading = () => store.dispatch(actions.openLoading());
export const closeLoading = () => store.dispatch(actions.closeLoading());
export const loadingReducer = loadingSlice.reducer;
