import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as WarningIcon } from 'assets/icons/warning-circle.svg';

import { useAppSelector } from 'store';
import { closeGameNotes } from 'store/gameSlice';

import { ContainedButton } from 'components/Button';
import { useModal } from 'components/Modal';
import { Heading4, Heading5 } from 'components/Typography';
import styled from 'styled-components';

export const useHostLogin = () => {
  const { Modal, closeModal, openModal, open } = useModal();
  const errorMessage = useRef<string>('');

  const renderHostLoginFailModal = useCallback(() => {
    return (
      <Modal
        style={{
          content: {
            width: 400,
          },
        }}
        header=''
        type='custom'
        submitOnEnter
      >
        <div style={{ textAlign: 'center', marginTop: 60, marginBottom: 80 }}>
          <WarningIcon />
          <Heading4 style={{ marginBottom: 10, marginTop: 15 }}>
            Login failed!
          </Heading4>
          <Heading5>{errorMessage.current}</Heading5>
        </div>
        <ContainedButton
          size='xl'
          onClick={closeModal}
          style={{ width: '100%', backgroundSize: '100% 100%' }}
        >
          OK
        </ContainedButton>
      </Modal>
    );
  }, [open]);

  const onOpenModal = (error: string) => {
    errorMessage.current = error;
    openModal();
  };

  return {
    renderHostLoginFailModal,
    openModal: onOpenModal,
  };
};

export const useGameNotes = (notes: string) => {
  const showGameNotes = useAppSelector((state) => state.game.showGameNotes);
  const { Modal, closeModal, openModal, open } = useModal(showGameNotes);
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeGameNotes());
    closeModal();
  }, []);

  const notesList = useMemo(
    () => notes.split('\n').filter((line) => line.trim() !== ''),
    [notes],
  );

  const renderGameNotesModal = useCallback(() => {
    return (
      <Modal
        style={{
          content: {
            width: 400,
          },
        }}
        header=''
        type='custom'
        submitOnEnter
      >
        <div style={{ textAlign: 'center', marginTop: 60, marginBottom: 80 }}>
          <WarningIcon />
          <Heading4 style={{ marginBottom: 10, marginTop: 15 }}>
            Game Notes
          </Heading4>
          <ListWrap>
            {notesList.map((line, index) => (
              <ListItem key={index}>{line}</ListItem>
            ))}
          </ListWrap>
        </div>
        <ContainedButton
          size='xl'
          onClick={handleCloseModal}
          style={{ width: '100%', backgroundSize: '100% 100%' }}
        >
          OK
        </ContainedButton>
      </Modal>
    );
  }, [open]);

  return {
    renderGameNotesModal,
    openModal,
  };
};

const ListWrap = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  box-sizing: border-box;
  color: #fff;
`;

const ListItem = styled.li`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  text-transform: uppercase;
`;
