interface IConfig {
  NODE_ENV: string;
  REACT_APP_API_URL: string;
  REACT_APP_LAUNCHPAD_BASE_URL: string;
  REACT_APP_WEB_SOCKET_URL: string;
  REACT_APP_FEUD_FE_SENTRY_DSN: string;
}

export const Config: IConfig = {
  NODE_ENV: process.env.NODE_ENV || window._env_.NODE_ENV,
  REACT_APP_API_URL:
    process.env.REACT_APP_API_URL || window._env_.REACT_APP_API_URL,
  REACT_APP_LAUNCHPAD_BASE_URL:
    process.env.REACT_APP_LAUNCHPAD_BASE_URL ||
    window._env_.REACT_APP_LAUNCHPAD_BASE_URL,
  REACT_APP_WEB_SOCKET_URL:
    process.env.REACT_APP_WEB_SOCKET_URL ||
    window._env_.REACT_APP_WEB_SOCKET_URL,
  REACT_APP_FEUD_FE_SENTRY_DSN:
    process.env.REACT_APP_FEUD_FE_SENTRY_DSN ||
    window._env_.REACT_APP_FEUD_FE_SENTRY_DSN,
};
