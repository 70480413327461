import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import VersusImage from 'assets/images/versus.png';

import { useGetTeamWaitingData } from 'pages/hooks';

import { ContainedButton } from 'components/Button';
import { PageContent, PageLayout, PageNavBar } from 'components/Layout';
import { Heading2, Heading4 } from 'components/Typography';
import styled from 'styled-components';

import {
  addTeamMember,
  editTeamMember,
  getTeamMembers,
  startGameApi,
} from 'api/team-waiting';

type TeamItem = {
  team: 0 | 1;
  index: number;
  name?: string;
};

export const TeamWaitingPage = () => {
  const navigate = useNavigate();
  const { id: eventId, roomId } = useParams();

  const [hoverItem, setHoverItem] = useState<TeamItem>(null);
  const [editItem, setEditItem] = useState<TeamItem>(null);
  // const [gameTeam, setGameTeam] = useState<GameTeam[]>([]);

  const { gameTeam, setGameTeam } = useGetTeamWaitingData(roomId);

  const disableStartGame =
    !gameTeam?.[0]?.members.length || !gameTeam?.[1]?.members.length
      ? true
      : false;

  const fetchTeamMembers = useCallback(() => {
    getTeamMembers(
      {
        eventId,
        roomId,
      },
      setGameTeam,
    );
  }, [setGameTeam, eventId, roomId]);

  const renderTeamInfo = (teamIdx: TeamItem['team']) => {
    const teamInfo = gameTeam[teamIdx];

    if (!teamInfo) {
      return null;
    }
    const { id: teamId, name, members } = teamInfo;

    return (
      <TeamWrapper>
        <Heading2
          color='primary'
          style={{ marginBottom: 32, paddingLeft: 32, paddingRight: 32 }}
        >
          Team {name}
        </Heading2>
        <div
          style={{
            flexGrow: 1,
            overflow: 'auto',
            paddingLeft: 32,
            paddingRight: 20,
          }}
        >
          {new Array(12).fill(null).map((_v, memIndex) => {
            const onSubmitName = async () => {
              const memberId = members[memIndex]?.id;

              const newMemberIndex =
                [...members].reduce(
                  (res, curr) => (curr.index < res ? res : curr.index),
                  -1,
                ) + 1;

              memberId
                ? await editTeamMember(
                    memberId,
                    editItem.name,
                    fetchTeamMembers,
                  )
                : await addTeamMember(
                    teamId,
                    editItem.name,
                    newMemberIndex,
                    fetchTeamMembers,
                  );
              setEditItem(null);
            };

            return (
              <TeamRow
                key={memIndex}
                onMouseOver={() =>
                  setHoverItem({
                    team: teamIdx,
                    index: memIndex,
                  })
                }
                onMouseOut={() => setHoverItem(null)}
              >
                <Heading4 style={{ marginRight: 10, lineHeight: '24px' }}>
                  #{memIndex + 1}
                </Heading4>
                {editItem?.team === teamIdx && editItem?.index === memIndex ? (
                  <input
                    placeholder={'Player Name'}
                    value={editItem.name}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmitName();
                      }
                    }}
                    onChange={(event) =>
                      setEditItem((prev) => ({
                        ...prev,
                        name: event.target.value,
                      }))
                    }
                    autoFocus
                  />
                ) : (
                  <Heading4>{members[memIndex]?.name || ''}</Heading4>
                  // <Heading4>{members[memIndex]?.name}</Heading4>
                )}

                {editItem?.team === teamIdx && editItem?.index === memIndex ? (
                  <Heading4
                    color='success'
                    className='action'
                    onClick={onSubmitName}
                  >
                    Save
                  </Heading4>
                ) : hoverItem?.team === teamIdx &&
                  hoverItem?.index === memIndex ? (
                  <Heading4
                    color='orange'
                    className='action'
                    onClick={() =>
                      setEditItem({
                        team: teamIdx,
                        index: memIndex,
                        name: members[memIndex]?.name || '',
                      })
                    }
                  >
                    Edit
                  </Heading4>
                ) : null}
              </TeamRow>
            );
          })}
          {/* {new Array(12).fill(null).map((_v, memIndex) => {
            const onSubmitName = () => {
              if (members.find((member) => member.index === memIndex)) {
                const memberId = members.find(
                  (member) => member.index === memIndex,
                )?.id;
                memberId &&
                  editTeamMember(memberId, editItem.name, fetchTeamMembers);
              } else {
                addTeamMember(
                  teamId,
                  editItem.name,
                  fetchTeamMembers,
                  memIndex,
                );
              }
              setEditItem(null);
            };

            return (
              <TeamRow
                key={memIndex}
                onMouseOver={() =>
                  setHoverItem({
                    team: teamIdx,
                    index: memIndex,
                  })
                }
                onMouseOut={() => setHoverItem(null)}
              >
                <Heading4 style={{ marginRight: 10, lineHeight: '24px' }}>
                  #{memIndex + 1}
                </Heading4>
                {editItem?.team === teamIdx && editItem?.index === memIndex ? (
                  <input
                    placeholder={'Player Name'}
                    value={editItem.name}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmitName();
                      }
                    }}
                    onChange={(event) =>
                      setEditItem((prev) => ({
                        ...prev,
                        name: event.target.value,
                      }))
                    }
                    autoFocus
                  />
                ) : (
                  <Heading4>
                    {members.find((member) => member.index === memIndex)
                      ?.name || ''}
                  </Heading4>
                  // <Heading4>{members[memIndex]?.name}</Heading4>
                )}

                {editItem?.team === teamIdx && editItem?.index === memIndex ? (
                  <Heading4
                    color='success'
                    className='action'
                    onClick={onSubmitName}
                  >
                    Save
                  </Heading4>
                ) : hoverItem?.team === teamIdx &&
                  hoverItem?.index === memIndex ? (
                  <Heading4
                    color='orange'
                    className='action'
                    onClick={() =>
                      setEditItem({
                        team: teamIdx,
                        index: memIndex,
                        name:
                          members.find((member) => member.index === memIndex)
                            ?.name || '',
                      })
                    }
                  >
                    Edit
                  </Heading4>
                ) : null}
              </TeamRow>
            );
          })} */}
        </div>
      </TeamWrapper>
    );
  };

  return (
    <PageLayout>
      <PageNavBar
        LeftContent={<Heading2>TEAM ASSIGNMENTS</Heading2>}
        MenuContent={null}
      />

      <PageContent>
        <Container>
          {renderTeamInfo(0)}
          {gameTeam.length ? <img src={VersusImage} /> : null}
          {renderTeamInfo(1)}
          {/* Copy Button */}
          {/* {gameTeam.length ? (
            <CopyButton size='sm' onClick={onCopy}>
              Copy
            </CopyButton>
          ) : null} */}
        </Container>
      </PageContent>
      <StartButton
        disabled={disableStartGame}
        size='md'
        onClick={() =>
          startGameApi(roomId, () => navigate(`/game/main-stage/${roomId}`))
        }
      >
        Start Game
      </StartButton>
    </PageLayout>
  );
};

const StartButton = styled(ContainedButton)`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 42px;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 480px 100px 480px;
  grid-gap: 60px;

  > img {
    align-self: center;
    width: 90px;
    height: 48px;
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 32px 0;
  max-height: calc(100vh - 250px);
  height: fit-content;
`;
const TeamRow = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 9px 0;
  position: relative;
  .action {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 4;
  }
  input {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    height: 22px;
    width: 100%;
    padding-right: 48px;
    ::placeholder {
      color: var(--white-40);
    }
  }
`;
