import uuidv4 from 'uuidv4';
import * as yup from 'yup';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { ReactComponent as TeamPhotoPlaceholderIcon } from 'assets/icons/team-photo-placeholder.svg';

import { yupResolver } from '@hookform/resolvers/yup';
import { getImageSrc } from 'helpers/getImageSrc';

import { RootState, useAppSelector } from 'store';
import { GameStatus } from 'store/gameplaySlice';

import { ModalProps } from 'components/Modal';
import { TeamsListUpdate } from 'pages/game-prepare/BreakoutRoomPage';
import styled from 'styled-components';

import { uploadFile } from 'api/file';

export const DEFAULT_TEAMPLAYERS_NUMBER = 8;

interface PropsType {
  isOpen: boolean;
  closeModal: () => void;
  Modal: (props: Omit<ModalProps, 'isOpen'>) => JSX.Element;
  teamData: any;
  editTeam: (body: TeamsListUpdate) => void;
  disabled: boolean;
}

const schema: yup.ObjectSchema<FieldValues> = yup.object({
  name: yup.string().required(),
});

const EditTeam: React.FC<PropsType> = ({
  isOpen,
  closeModal,
  Modal,
  teamData,
  editTeam,
  disabled,
}) => {
  const game = useAppSelector((state: RootState) => state.game.game);

  const isGameAlreadyGoing = useMemo(
    () => game.status === GameStatus.playing,
    [game],
  );

  const [playersFields, setPlayersFields] = useState<number>(
    DEFAULT_TEAMPLAYERS_NUMBER,
  );

  const initialState = useMemo(
    () => ({
      id: teamData.id,
      name: teamData.name,
      index: teamData.index,
      members: teamData.members,
      teamPhoto: teamData.teamPhoto,
    }),
    [],
  );

  const { register, handleSubmit, watch, getValues, setValue } =
    useForm<FieldValues>({
      defaultValues: initialState,
      resolver: yupResolver(schema),
    });

  const teamPhoto = watch('teamPhoto', getValues().teamPhoto);
  const membersWatch: any[] = watch('members', getValues().members);

  useEffect(() => {
    if (membersWatch.length > playersFields) {
      setPlayersFields(membersWatch.length);
    }
  }, [membersWatch]);

  const handleIncrementPlayerFields = useCallback(() => {
    const membersLastIndex = membersWatch.reduce(
      (res, curr) => (curr.index + 1 > res ? curr.index + 1 : res),
      DEFAULT_TEAMPLAYERS_NUMBER,
    );

    setPlayersFields((prev) => ++prev);
    setValue(`members[${membersWatch.length}]`, {
      id: uuidv4(),
      index: membersLastIndex,
      name: '',
      isMock: true,
    });
  }, [membersWatch, playersFields]);

  const playerElements = useMemo(
    () =>
      Array.from(
        {
          length:
            playersFields > DEFAULT_TEAMPLAYERS_NUMBER
              ? playersFields
              : DEFAULT_TEAMPLAYERS_NUMBER,
        },
        (_, index) => (
          <PlayerWrap key={index}>
            <PlayerIndex>{index + 1}</PlayerIndex>
            <EditModalInput
              placeholder={'PLAYER NAME'}
              {...register(`members.${index}.name`)}
              disabled={isGameAlreadyGoing}
            />
          </PlayerWrap>
        ),
      ),
    [playersFields, register],
  );

  const handleUploadTeamPhoto = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0];

      if (file) {
        const fileId = await uploadFile(file);

        setValue('teamPhoto', fileId, { shouldDirty: true });
      }
    },
    [],
  );

  const teamPhotoSrc = useMemo(
    () => teamPhoto && getImageSrc(teamPhoto, 114),
    [teamPhoto],
  );

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const dto = {
        ...data,
        initialMembers: initialState.members,
      };
      editTeam(dto as TeamsListUpdate);
    },
    [editTeam, closeModal, initialState],
  );

  const handleSubmitForm = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  // const handleKeyDown = (e: React.KeyboardEvent) => {
  //   if (e.key === 'Enter') {
  //     handleSubmitForm();
  //   }
  // };

  return (
    isOpen && (
      <Modal
        type='action'
        isEditTeam
        submitAction={{
          title: 'SAVE',
          onSubmit: handleSubmitForm,
          buttonColor: 'success',
          disabled,
        }}
        header={'EDIT TEAM'}
        style={{
          content: {
            width: 540,
            boxSizing: 'border-box',
            height: '90%',
            maxHeight: '620px',
            overflow: 'hidden',
          },
        }}
        footerStyle={{
          background:
            'radial-gradient(179.49% 121.82% at 23.89% 0%, #005FCE 0%, #16367C 100%)',
          boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
        shouldCloseOnOverlayClick
        submitOnEnter
      >
        <EditModalContent>
          <TeamInfoWrap>
            <TeamPhotoWrap htmlFor={`team_${initialState.index}`}>
              {teamPhotoSrc ? (
                <img src={teamPhotoSrc} />
              ) : (
                <TeamPhotoPlaceholderIcon />
              )}
              <input
                id={`team_${initialState.index}`}
                type={'file'}
                onChange={handleUploadTeamPhoto}
              />
            </TeamPhotoWrap>
            <TeamNameWrap>
              <EditModalInput
                type='text'
                placeholder={'Team Name'}
                {...register(`name`)}
              />
            </TeamNameWrap>
          </TeamInfoWrap>
          <TeamPlayersWrap>
            <TeamPlayers>{playerElements}</TeamPlayers>
            {playerElements.length < 12 && (
              <AddPlayer
                onClick={handleIncrementPlayerFields}
                type={'button'}
                disabled={isGameAlreadyGoing}
              >
                Add Player
              </AddPlayer>
            )}
          </TeamPlayersWrap>
        </EditModalContent>
      </Modal>
    )
  );
};

export const EditModalContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1.5rem;
`;

export const EditModalInput = styled.input`
  position: relative;
  width: 100%;
  font-size: 1.25rem;
  color: #fff;
  text-transform: uppercase;
  background: none;
  outline: none;
  border: none;
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};

  &::before {
    display: ${({ disabled }) => (disabled ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
    z-index: 3;
  }
`;

export const TeamInfoWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  gap: 1.5rem;
`;

export const TeamPhotoWrap = styled.label`
  position: relative;
  width: 114px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow: hidden;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & > input {
    display: none;
  }
`;

export const TeamNameWrap = styled.div`
  position: relative;
  flex-grow: 1;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.2);
`;

export const TeamPlayersWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export const TeamPlayers = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const PlayerWrap = styled.li`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
`;

export const AddPlayer = styled.button`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  text-transform: uppercase;

  &::before {
    display: ${({ disabled }) => (disabled ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
    z-index: 3;
  }
`;

export const PlayerIndex = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #fff;
  background: #2d73cc;
  border-radius: 50%;
`;

// export const CloseBtn = styled.button`
//   position: relative;
//   background: inherit;
//   outline: none;
//   border: none;
//   cursor: pointer;
// `;

// export const EditModalFooter = styled.footer`
//   position: relative;
//   width: 100%;
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   justify-content: space-between;
//   padding: 1rem 1.5rem 0 1rem;

//   &::before {
//     display: block;
//     content: '';
//     position: absolute;
//     width: calc(100% + 2rem);
//     height: calc(100% + 1rem);
//     top: 0;
//     left: calc(50%);
//     transform: translate(calc(-50% - 1rem), 1rem);
//     background: radial-gradient(
//       179.49% 121.82% at 23.89% 0%,
//       #005fce 0%,
//       #16367c 100%
//     );
//     box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
//     z-index: -1;
//   }
// `;

export default EditTeam;
