import { FC } from 'react';

import { usePlayoffBracket } from './hooks';

import { Heading2, Heading3, Heading4 } from 'components/Typography';

import {
  BracketLine,
  BracketProps,
  FourTeamsChampionBracket,
  GridSpan,
} from './PlayoffBracket';

export const FourTeamsBracket: FC<BracketProps> = ({ allowClick }) => {
  const { renderTeamsByRoom } = usePlayoffBracket(allowClick, 'large');

  return (
    <FourTeamsChampionBracket>
      <GridSpan span={14} />

      <Heading3 color='primary' style={{ textAlign: 'center' }}>
        Semifinal
      </Heading3>
      <GridSpan span={6} />
      {renderTeamsByRoom(0, [2, 3])}
      <GridSpan span={3} />

      <GridSpan span={6} />
      <BracketLine className='up' span={3} />
      <BracketLine className='down' span={3} />
      <GridSpan span={2} />

      <Heading2 color='primary' style={{ textAlign: 'center' }}>
        Championship
      </Heading2>
      <GridSpan span={2} />
      <Heading4>1st Place Matchup</Heading4>
      {renderTeamsByRoom(2, [])}
      <GridSpan span={1} />
      <Heading4>3rd Place Matchup</Heading4>
      {renderTeamsByRoom(3, [])}

      <GridSpan span={6} />
      <BracketLine className='down' span={3} />
      <BracketLine className='up' span={3} />
      <GridSpan span={2} />

      <Heading3 color='primary' style={{ textAlign: 'center' }}>
        Semifinal
      </Heading3>
      <GridSpan span={6} />
      {renderTeamsByRoom(1, [2, 3])}
      <GridSpan span={3} />

      <GridSpan span={14} />
    </FourTeamsChampionBracket>
  );
};
