import { useCallback, useMemo } from 'react';

import Select from 'components/Select';
import { Heading3 } from 'components/Typography';
import styled from 'styled-components';

export enum roles {
  HOST = 'host',
  CO_HOST = 'coHost',
  GAMELEADER = 'gameLeader',
}

export enum hostStatuses {
  SCHEDULED = 'SCHEDULED',
  ACTUAL = 'ACTUAL',
  UNASSIGNED_ACTUAL = 'UNASSIGNED_ACTUAL',
}

type PropsType = {
  roomInfo: any;
  hosts: Array<any>;
  rooms: Array<any>;
  changeHost: ({ newHost, roomId, hostType }) => void;
};

const AssignHostsRoom: React.FC<PropsType> = ({
  roomInfo,
  hosts,
  rooms,
  changeHost,
}) => {
  const availableHosts = useMemo(
    () =>
      hosts
        .filter(
          (host) =>
            host.role === roles.HOST &&
            !rooms.find(
              (room) =>
                (room.host?.id === host.host.id && room.id !== roomInfo.id) ||
                room.coHost?.id === host.host?.id,
            ),
        )
        .reduce((res, curr) => {
          !res.find((host) => host.host.id === curr.host.id) && res.push(curr);
          return res;
        }, [])
        .map(({ host }) => ({
          value: host.id,
          label: `${host.firstName} ${host.lastName}`,
        })),
    [rooms, hosts],
  );

  const availableCoHosts = useMemo(
    () =>
      hosts
        .filter((host) => !rooms.find((room) => room.host?.id === host.host.id))
        .reduce((res, curr) => {
          !res.find((host) => host.host.id === curr.host.id) && res.push(curr);
          return res;
        }, [])
        .map(({ host }) => ({
          value: host.id,
          label: `${host.firstName} ${host.lastName}`,
        }))
        .concat({
          value: '00000000-0000-0000-0000-000000000000',
          label: 'None',
        }),
    [rooms, hosts],
  );

  const handleChangeHost = useCallback(
    (newHost: string | null, hostType: roles) => {
      changeHost({ newHost, hostType, roomId: roomInfo.id });
    },
    [roomInfo],
  );

  return (
    <div
      style={{
        padding: 16,
        gap: '.5rem',
        background: '#2D73CC',
        borderRadius: '1rem',
        display: 'flex',
        flexFlow: 'column nowrap',
        height: 'fit-content',
      }}
    >
      <Heading3>{`${roomInfo.teamA.name} VS. ${roomInfo.teamB.name}`}</Heading3>

      <HostWrap>
        <HostLabel
        // disabled={disabledHostsAssignment}
        >
          Host
        </HostLabel>
        <HostSelect>
          <Select
            value={roomInfo.host?.id || ''}
            setValue={(newHost: string) =>
              handleChangeHost(newHost, roles.HOST)
            }
            options={availableHosts}
            // disabled={disabledHostsAssignment}
          />
        </HostSelect>
      </HostWrap>

      <HostWrap>
        <HostLabel
        // disabled={disabledCoHostsAssignment}
        >
          Co-Host
        </HostLabel>
        <HostSelect>
          <Select
            value={roomInfo.coHost && roomInfo.coHost.id}
            setValue={(newHost: string | null) =>
              handleChangeHost(newHost, roles.CO_HOST)
            }
            options={availableCoHosts}
            // disabled={disabledCoHostsAssignment}
          />
        </HostSelect>
      </HostWrap>
    </div>
  );
};

export default AssignHostsRoom;

const HostWrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
`;

const HostLabel = styled.label`
  color: #ffae01;
  font-weight: 400;
  width: 90px;
  text-align: left;
`;

const HostSelect = styled.div`
  position: relative;
  flex-grow: 1;
  color: #fff;
`;
