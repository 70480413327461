import qs from 'query-string';

import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import BackgroundLogo from 'assets/images/logo.png';

import { useHostLogin } from './hooks';

import { ContainedButton } from 'components/Button';
import { CodeInput, Input, InputGroup } from 'components/Input';
import { Heading5 } from 'components/Typography';
import { roles } from 'components/assign-hosts-room/AssignHostsRoom';
import { PageContainer } from 'pages/LoginPage';

import { loginGameViaCode } from 'api/auth';

export const GameLoginPage = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [roomId, setRoomId] = useState('');
  const { renderHostLoginFailModal, openModal } = useHostLogin();

  useEffect(() => {
    const { code, roomId } = qs.parse(location.search);
    if (code?.length === 4) {
      setCode(String(code));
    }

    if (roomId) {
      setRoomId(String(roomId));
    }

    setTimeout(() => setFirstLoad(false), 1000);
  }, [location.search]);

  const handleCodeChange = useCallback((value: string) => {
    setCode(value);
  }, []);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      loginGameViaCode(
        {
          expectedRole: type as roles,
          eventId: id,
          code,
          email,
        },
        () =>
          roomId
            ? navigate(`/game/${id}/room/${roomId}`)
            : navigate(`/game/${id}`),
        (errorMessage) => openModal(errorMessage),
      );
    },
    [id, type, code, email, roomId],
  );

  return (
    <PageContainer
      firstLoad={firstLoad}
      className='flex-between'
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: 100,
        boxSizing: 'border-box',
        maxHeight: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <img src={BackgroundLogo} alt='logo' id='logo' />
      <form onSubmit={handleSubmit}>
        <div
          style={{
            maxWidth: '100%',
            padding: '0 20px',
            boxSizing: 'border-box',
            width: 'auto',
          }}
        >
          <Heading5>Enter login code.</Heading5>
          <Heading5 style={{ marginBottom: 24 }}>
            If you don’t have the code, please ASK the admin.
          </Heading5>
          <CodeInput
            value={code}
            numInputs={4}
            onChange={handleCodeChange}
            renderInput={(props) => <input {...props} />}
          />
          <InputGroup title='Your email'>
            <Input
              placeholder='email address'
              name='email'
              type='email'
              onChange={handleEmailChange}
            />
          </InputGroup>
          <ContainedButton
            style={{ width: 408, backgroundSize: '100% 100%', height: 72 }}
            disabled={code.length < 4}
            type='submit'
          >
            Login
          </ContainedButton>
        </div>
      </form>
      {renderHostLoginFailModal()}
    </PageContainer>
  );
};
