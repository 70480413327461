import { union } from 'lodash';

import {
  // EventData,
  GamePlaying, // GameStatus,
  // GameTeam,
} from 'store/gameplaySlice';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// import { HostItem } from 'api/default-settings';

export interface Tournament {
  isHosting: boolean;
  isNextMatchup: number;
  roomIndex: number;
  teams: {
    matchId: number;
    teamName: string;
    point: number;
  }[];
  room?: GamePlaying;
  // room?: {
  //   id: number;
  //   winner?: {
  //     id: string;
  //     index: number;
  //     name: string;
  //   };
  //   teamA?: GameTeam;
  //   teamB?: GameTeam;
  //   host?: HostItem;
  //   coHost?: HostItem;
  //   isStart: number;
  //   game?: {
  //     id: string;
  //     status: GameStatus;
  //   };
  //   createdAt?: string;
  // };
  isStart: number;
}

type State = {
  bracket: Tournament[];
  roomId: number;
};

const initialState: State = {
  bracket: [],
  roomId: null,
};

const playoffBracketSlice = createSlice({
  name: 'playoffBracket',
  initialState,
  reducers: {
    setPlayoffBracket: (state, action: PayloadAction<Tournament[]>) => {
      state.bracket = action.payload;
    },
    selectRoom: (state, action: PayloadAction<number>) => {
      state.roomId = action.payload === state.roomId ? null : action.payload;
    },
    resetBracket: () => {
      return initialState;
    },
  },
});
export const { setPlayoffBracket } = playoffBracketSlice.actions;

export const playoffBracketReducer = playoffBracketSlice.reducer;

// For persistent result state

type ResultState = {
  display: {
    roomIndex: number;
    teamIndex: number[];
  }[];
  reveal: number[];
};
const initialResultState: ResultState = {
  display: [],
  reveal: [],
};
const playoffBracketResultSlice = createSlice({
  name: 'playoffBracketResult',
  initialState: initialResultState,
  reducers: {
    setDisplayTeam: (
      state,
      action: PayloadAction<{
        roomIndex: number;
        teamIndex: number;
      }>,
    ) => {
      const curTeam = state.display?.findIndex(
        (el) => el.roomIndex === action.payload.roomIndex,
      );
      if (curTeam === -1) {
        state.display = [
          ...state.display,
          {
            roomIndex: action.payload.roomIndex,
            teamIndex:
              action.payload.teamIndex !== undefined
                ? [action.payload.teamIndex]
                : [],
          },
        ];
      } else if (action.payload.teamIndex !== undefined) {
        state.display[curTeam].teamIndex = union(
          state.display[curTeam]?.teamIndex || [],
          [action.payload.teamIndex],
        );
      }
    },
    setDefaultDisplayTeams: (state, action: PayloadAction<number>) => {
      state.display = Array.from(Array(action.payload).keys()).map(
        (roomIndex) => ({
          roomIndex,
          teamIndex: [roomIndex * 2, roomIndex * 2 + 1],
        }),
      );
    },
    revealWinnerResult: (state, action: PayloadAction<number>) => {
      state.reveal = union(state.reveal, [action.payload]);
    },
    revealWinnersResult: (state, action: PayloadAction<number[]>) => {
      state.reveal = union(state.reveal, action.payload);
    },
    resetBracketReveal: () => {
      return initialResultState;
    },
  },
});

export const {
  setDisplayTeam,
  setDefaultDisplayTeams,
  revealWinnerResult,
  revealWinnersResult,
  resetBracketReveal,
} = playoffBracketResultSlice.actions;

export const playoffBracketResultReducer = playoffBracketResultSlice.reducer;
