import { FC } from 'react';

import { usePlayoffBracket } from './hooks';

import { Heading2, Heading3, Heading4 } from 'components/Typography';

import {
  BracketLine,
  BracketProps,
  EightTeamsChampionBracket,
  FourTeamsChampionBracket,
  GridSpan,
  SixteenTeamsChampionBracket,
} from './PlayoffBracket';

export const SixteenTeamsBracket: FC<BracketProps> = ({ allowClick }) => {
  const { renderTeamsByRoom } = usePlayoffBracket(allowClick);
  return (
    <>
      <div>
        <SixteenTeamsChampionBracket>
          <GridSpan span={24} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Round of Sixteen
          </Heading3>
          <GridSpan />
          {renderTeamsByRoom(0, [8, 12])}

          <GridSpan span={2} />
          {renderTeamsByRoom(1, [8, 12])}
          <GridSpan span={2} />

          {renderTeamsByRoom(2, [9, 13])}
          <GridSpan span={2} />

          {renderTeamsByRoom(3, [9, 13])}

          <GridSpan span={4} />
          <BracketLine className='down' />

          <GridSpan span={2} />
          <BracketLine className='up' />

          <GridSpan span={6} />
          <BracketLine className='down' />

          <GridSpan span={2} />
          <BracketLine className='up' />
          <GridSpan span={2} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Quarterfinal
          </Heading3>
          <GridSpan span={4} />

          {renderTeamsByRoom(8, [16, 18])}

          <GridSpan span={8} />

          {renderTeamsByRoom(9, [16, 18])}

          <GridSpan span={7} />
          <BracketLine className='down' span={5} />
          <GridSpan span={2} />
          <BracketLine className='up' span={5} />
          <GridSpan span={5} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Semifinal
          </Heading3>
          <GridSpan span={10} />

          {renderTeamsByRoom(16, [24, 25])}
          <GridSpan span={9} />

          <GridSpan span={10} />
          <BracketLine className='up' span={3} />
          <BracketLine className='down' span={3} />

          <GridSpan span={8} />
          <Heading2 color='primary' style={{ textAlign: 'center' }}>
            Championship
          </Heading2>
          <GridSpan span={3} />

          <GridSpan span={3} />
          <Heading4>1st Place Matchup</Heading4>
          {renderTeamsByRoom(24, [])}
          <GridSpan />

          <Heading4>3rd Place Matchup</Heading4>
          {renderTeamsByRoom(25, [])}
          <GridSpan span={6} />

          <GridSpan span={10} />
          <BracketLine className='down' span={3} />
          <BracketLine className='up' span={3} />
          <GridSpan span={8} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Semifinal
          </Heading3>
          <GridSpan span={10} />

          {renderTeamsByRoom(17, [24, 25])}
          <GridSpan span={9} />

          <GridSpan span={7} />
          <BracketLine className='up' span={5} />
          <GridSpan span={2} />

          <BracketLine className='down' span={5} />
          <GridSpan span={5} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Quarterfinal
          </Heading3>
          <GridSpan span={4} />

          {renderTeamsByRoom(10, [17, 19])}

          <GridSpan span={8} />

          {renderTeamsByRoom(11, [17, 19])}
          <GridSpan span={3} />

          <GridSpan span={4} />
          <BracketLine className='up' />

          <GridSpan span={2} />
          <BracketLine className='down' />

          <GridSpan span={6} />
          <BracketLine className='up' />

          <GridSpan span={2} />
          <BracketLine className='down' />
          <GridSpan span={2} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Round of Sixteen
          </Heading3>
          <GridSpan />

          {renderTeamsByRoom(4, [10, 14])}
          <GridSpan span={2} />

          {renderTeamsByRoom(5, [10, 14])}
          <GridSpan span={2} />

          {renderTeamsByRoom(6, [11, 15])}
          <GridSpan span={2} />

          {renderTeamsByRoom(7, [11, 15])}

          <GridSpan span={24} />
        </SixteenTeamsChampionBracket>
      </div>

      <div>
        <FourTeamsChampionBracket
          style={{
            gridTemplateColumns: `minmax(32px, auto) repeat(6, 200px 26px) 200px minmax(32px, auto)`,
            gridTemplateRows: 'repeat(14, 24px)',
          }}
        >
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />

          <GridSpan span={7} />
          {renderTeamsByRoom(18, [26, 27], true)}
          <GridSpan span={3} />
          <GridSpan span={6} />
          <BracketLine className='up' span={3} />
          <BracketLine className='down' span={3} />
          <GridSpan span={2} />

          <Heading2 color='primary' style={{ textAlign: 'center' }}>
            Consolation
          </Heading2>
          <GridSpan span={2} />
          <Heading4>5th Place Matchup</Heading4>
          {renderTeamsByRoom(26, [], true)}
          <GridSpan span={1} />
          <Heading4>7th Place Matchup</Heading4>
          {renderTeamsByRoom(27, [], true)}
          <GridSpan span={6} />
          <BracketLine className='down' span={3} />
          <BracketLine className='up' span={3} />
          <GridSpan span={2} />

          <GridSpan span={7} />
          {renderTeamsByRoom(19, [26, 27], true)}
          <GridSpan span={3} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
        </FourTeamsChampionBracket>
      </div>

      <div>
        <EightTeamsChampionBracket
          style={{
            gridTemplateColumns: `minmax(32px, auto) repeat(6, 200px 26px) 200px minmax(32px, auto)`,
            gridTemplateRows: 'repeat(14, 24px)',
            paddingTop: 48,
          }}
        >
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />

          {renderTeamsByRoom(12, [20, 22])}
          <GridSpan span={6} />
          {renderTeamsByRoom(13, [20, 22])}

          <GridSpan span={2} />
          <BracketLine className='down' span={4} />
          <GridSpan span={2} />
          <BracketLine className='up' span={4} />
          <GridSpan span={2} />

          <GridSpan span={5} />
          {renderTeamsByRoom(20, [28, 29])}
          <GridSpan span={5} />

          <GridSpan span={4} />
          <BracketLine className='up' span={3} />
          <BracketLine className='down' span={3} />
          <GridSpan span={4} />

          <GridSpan />
          <Heading4>9th Place Matchup</Heading4>
          {renderTeamsByRoom(28, [])}
          <GridSpan span={1} />
          <Heading4>11th Place Matchup</Heading4>
          {renderTeamsByRoom(29, [])}
          <GridSpan span={2} />

          <GridSpan span={4} />
          <BracketLine className='down' span={3} />
          <BracketLine className='up' span={3} />
          <GridSpan span={4} />

          <GridSpan span={5} />
          {renderTeamsByRoom(21, [28, 29])}
          <GridSpan span={5} />

          <GridSpan span={2} />
          <BracketLine className='up' span={4} />
          <GridSpan span={2} />
          <BracketLine className='down' span={4} />
          <GridSpan span={2} />

          {renderTeamsByRoom(14, [21, 23])}
          <GridSpan span={6} />
          {renderTeamsByRoom(15, [21, 23])}

          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
        </EightTeamsChampionBracket>
      </div>

      <div>
        <FourTeamsChampionBracket
          style={{
            gridTemplateColumns: `minmax(32px, auto) repeat(6, 200px 26px) 200px minmax(32px, auto)`,
            gridTemplateRows: 'repeat(14, 24px)',
          }}
        >
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />

          <GridSpan span={7} />
          {renderTeamsByRoom(22, [30, 31], true)}
          <GridSpan span={3} />

          <GridSpan span={6} />
          <BracketLine className='up' span={3} />
          <BracketLine className='down' span={3} />
          <GridSpan span={2} />

          <GridSpan span={3} />
          <Heading4>13th Place Matchup</Heading4>
          {renderTeamsByRoom(30, [], true)}
          <GridSpan span={1} />
          <Heading4>15th Place Matchup</Heading4>
          {renderTeamsByRoom(31, [], true)}

          <GridSpan span={6} />
          <BracketLine className='down' span={3} />
          <BracketLine className='up' span={3} />
          <GridSpan span={2} />

          <GridSpan span={7} />
          {renderTeamsByRoom(23, [30, 31], true)}
          <GridSpan span={3} />

          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
          <GridSpan span={14} />
        </FourTeamsChampionBracket>
      </div>
    </>
  );
};
