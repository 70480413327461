import { useCallback } from 'react';

import RevealAnswerItemBackground from 'assets/images/gameplay/answer-item-reveal.png';
import AnswerItemBackground from 'assets/images/gameplay/answer-item.png';
import { ReactComponent as NumberTagIcon } from 'assets/images/gameplay/number-tag.svg';

import { Display1, Display2, Display3 } from 'components/Typography';
import { AnswerType } from 'pages/gameplay/GamePlayPage';
import styled from 'styled-components';

const FlipCard = styled.div<{ revealed: boolean }>`
  width: 100%;
  height: 100%;
  perspective: 1000px;
  background-color: transparent;
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    .back {
      transform: rotateX(${({ revealed }) => (revealed ? '0' : '-180deg')});
    }
    .inner {
      ${({ revealed }) => (revealed ? 'transform: rotateX(180deg);' : '')}
    }
    .back,
    .inner {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: transform 0.6s;
    }
  }
`;

const AnswerItem = styled.div`
  background-image: url(${AnswerItemBackground});
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
`;

const ReviewAnswerItem = styled(AnswerItem)`
  background-image: url(${RevealAnswerItemBackground});
  background-size: 100% 100%;
  padding-left: 16px;
  padding-right: 6px;
  box-sizing: border-box;
  text-align: left;
  span {
    background: #0000f5;
    border: 4px solid #000000;
    min-width: 8vw;
    height: calc(14vh - 24px);
    border-bottom-right-radius: 8px;
  }
`;

const NumberTag = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: left linear 0.2s;
  /* transition-delay: 0.5s; */
  > svg {
    position: absolute;
    z-index: 1;
  }
  p {
    z-index: 2;
  }
`;

type PropsType = {
  answer: AnswerType | null;
  index: number;
  revealAnswers: Array<string>;
  displayAnswers: number;
  handleClickAnswer: (index: number) => void;
};

const FlipCardItem: React.FC<PropsType> = ({
  answer,
  index,
  revealAnswers,
  displayAnswers,
  handleClickAnswer,
}) => {
  const onAnswerClick = useCallback(
    () => handleClickAnswer(index),
    [handleClickAnswer, index],
  );
  return (
    <FlipCard revealed={!answer.isEmpty && revealAnswers.includes(answer.id)}>
      <div>
        <AnswerItem
          // key={index}
          className={`flex-center bg-center inner`}
          onClick={onAnswerClick}
        >
          {!answer.isEmpty ? (
            <NumberTag
              className='flex-center'
              style={{
                left: displayAnswers > index + 1 ? '50%' : '100%',
                opacity: displayAnswers > index + 1 ? 1 : 0,
              }}
            >
              <NumberTagIcon />
              <Display1>{index + 1}</Display1>
            </NumberTag>
          ) : null}
        </AnswerItem>
        <ReviewAnswerItem className={`flex-between back`}>
          <Display3
            style={{
              maxWidth: '18vw',
              fontSize: answer?.answer?.length > 24 ? '1.6vw' : '2.25vw',
              lineHeight: answer?.answer?.length > 24 ? '26px' : '36px',
            }}
          >
            {answer?.answer}
          </Display3>
          <span className='flex-center'>
            <Display2 style={{ fontSize: '4vw' }}>{answer?.point}</Display2>
          </span>
        </ReviewAnswerItem>
      </div>
    </FlipCard>
  );
};

export default FlipCardItem;
