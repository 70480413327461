import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as WarningIcon } from 'assets/icons/warning-circle.svg';

import store from '../../store';
import { resetAuth } from '../../store/authSlice';

import { ContainedButton } from 'components/Button';
import { useModal } from 'components/Modal';
import { Heading4, Heading5 } from 'components/Typography';

import { ERROR_406 } from 'api/auth';

export const useGameDone = (gameId: string, role: string) => {
  const navigate = useNavigate();
  const { Modal, closeModal, openModal, open } = useModal();

  const handleNavigation = useCallback(() => {
    const formattedRole = role.charAt(0).toLowerCase() + role.slice(1);
    navigate(`/login/${formattedRole}/game/${gameId}`);
    store.dispatch(resetAuth());
  }, [navigate, gameId, role]);

  const onOK = useCallback(() => {
    closeModal();
    handleNavigation();
  }, [handleNavigation]);

  const renderGameDoneModal = useCallback(
    (notStated?: boolean) => {
      return (
        <Modal
          style={{
            content: {
              width: 400,
            },
          }}
          header=''
          type='custom'
          onAfterClose={handleNavigation}
          shouldCloseOnOverlayClick={!notStated}
          submitOnEnter
        >
          <div style={{ textAlign: 'center', marginTop: 60, marginBottom: 80 }}>
            <WarningIcon />
            {notStated ? (
              <Heading5 style={{ marginBottom: 10, marginTop: 15 }}>
                {ERROR_406}
              </Heading5>
            ) : (
              <Heading4 style={{ marginBottom: 10, marginTop: 15 }}>
                LOGIN FAILED!
              </Heading4>
            )}
            <Heading5>{notStated ? null : 'THIS GAME HAS ENDED'}</Heading5>
          </div>
          {notStated ? null : (
            <ContainedButton
              size='xl'
              onClick={onOK}
              style={{ width: '100%', backgroundSize: '100% 100%' }}
            >
              OK
            </ContainedButton>
          )}
        </Modal>
      );
    },
    [open],
  );

  return {
    renderGameDoneModal,
    openModal,
  };
};
