import { ButtonHTMLAttributes, FC } from 'react';

import { ReactComponent as BackIcon } from 'assets/icons/arrow-circle-left.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import BtnBgDangerLgImage from 'assets/images/button-bg/danger-lg.png';
import BtnBgDangerMdImage from 'assets/images/button-bg/danger-md.png';
import BtnBgDangerSmImage from 'assets/images/button-bg/danger-sm.png';
import BtnBgPrimaryLgImage from 'assets/images/button-bg/primary-lg.png';
import BtnBgPrimaryMdImage from 'assets/images/button-bg/primary-md.png';
import BtnBgPrimarySmImage from 'assets/images/button-bg/primary-sm.png';
import BtnBgPrimaryXlImage from 'assets/images/button-bg/primary-xl.png';
import BtnBgSuccessLgImage from 'assets/images/button-bg/success-lg.png';
import BtnBgSuccessMdImage from 'assets/images/button-bg/success-md.png';
import BtnBgSuccessSmImage from 'assets/images/button-bg/success-sm.png';

import styled from 'styled-components';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';
export type ButtonColor = 'primary' | 'danger' | 'success';

type StyledButton = {
  variant?: 'contained' | 'outline';
  containedColor?: ButtonColor;
  size?: ButtonSize;
};

const DROP_SHADOW_BLUR_RADIUS = {
  lg: 40,
  md: 30,
  sm: 20,
  xl: 20,
};

const getDropShadow = (size: ButtonSize) => ({
  primary: `0px 0px ${DROP_SHADOW_BLUR_RADIUS[size]}px rgba(108, 220, 255, 0.5)`,
  danger: `0px 0px ${DROP_SHADOW_BLUR_RADIUS[size]}px rgba(255, 82, 82, 0.5)`,
  success: `0px 0px ${DROP_SHADOW_BLUR_RADIUS[size]}px #299547`,
});

const BUTTON_CONFIG = {
  xl: {
    width: 368,
    height: 48,
    fontSize: 16,
    bottom: 4,
    borderRadius: {
      contained: 14,
      outlined: 14,
    },
    btnBackgroundImage: {
      primary: BtnBgPrimaryXlImage,
      danger: BtnBgPrimaryXlImage,
      success: BtnBgPrimaryXlImage,
    },
  },
  lg: {
    width: 280,
    height: 78,
    fontSize: 24,
    bottom: 6,
    borderRadius: {
      contained: 20,
      outlined: 16,
    },
    btnBackgroundImage: {
      primary: BtnBgPrimaryLgImage,
      danger: BtnBgDangerLgImage,
      success: BtnBgSuccessLgImage,
    },
  },
  md: {
    width: 160,
    height: 48,
    fontSize: 16,
    bottom: 4,
    borderRadius: {
      contained: 14,
      outlined: 14,
    },
    btnBackgroundImage: {
      primary: BtnBgPrimaryMdImage,
      danger: BtnBgDangerMdImage,
      success: BtnBgSuccessMdImage,
    },
  },
  sm: {
    width: 120,
    height: 32,
    fontSize: 13,
    bottom: 3,
    borderRadius: {
      contained: 10,
      outlined: 12,
    },
    btnBackgroundImage: {
      primary: BtnBgPrimarySmImage,
      danger: BtnBgDangerSmImage,
      success: BtnBgSuccessSmImage,
    },
  },
};

const BasicButton = styled.button<StyledButton>`
  width: ${({ size = 'lg' }) => BUTTON_CONFIG[size].width}px;
  height: ${({ size = 'lg' }) => BUTTON_CONFIG[size].height}px;
  font-size: ${({ size = 'lg' }) => BUTTON_CONFIG[size].fontSize}px;
  text-transform: uppercase;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  color: #fff;
  font-family: fugaz-one;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

export const ContainedButton = styled(BasicButton)`
  border: none;
  background-color: transparent;
  background-image: ${({ size = 'lg', containedColor = 'primary' }) =>
    `url(${BUTTON_CONFIG[size].btnBackgroundImage[containedColor]})`};
  padding-bottom: ${({ size = 'lg' }) => BUTTON_CONFIG[size].bottom}px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: ${({ size = 'lg' }) =>
    BUTTON_CONFIG[size].borderRadius.contained}px;

  &:hover,
  &:active {
    filter: ${({ size = 'lg', containedColor = 'primary' }) =>
      `drop-shadow(${getDropShadow(size)[containedColor]})`};
  }
  :disabled {
    opacity: 0.5;
    filter: none !important;
    cursor: default;
  }
`;

export const OutlinedButton = styled(BasicButton)`
  background-color: transparent;
  border: 1px solid var(--white-10);
  border-radius: ${({ size = 'lg' }) =>
    BUTTON_CONFIG[size].borderRadius.outlined}px;
  ${({ size = 'lg' }) => (size === 'lg' ? 'height: 72px;' : '')}

  &:hover,
  &:active {
    background: var(--white-10);
  }

  svg {
    margin-left: 8px;
  }

  :disabled {
    opacity: 0.5;
    filter: none !important;
    cursor: default;
    background: none !important;
  }
`;

export const OutlinedButton2 = styled(BasicButton)`
  background-color: transparent;
  border: 3px solid var(--white-10);
  border-radius: ${({ size = 'lg' }) =>
    BUTTON_CONFIG[size].borderRadius.outlined}px;
  ${({ size = 'lg' }) => (size === 'lg' ? 'height: 72px;' : '')}

  &:hover,
  &:active {
    background: var(--white-10);
  }

  svg {
    margin-left: 8px;
  }

  :disabled {
    opacity: 0.5;
    filter: none !important;
    cursor: default;
    background: none !important;
  }
`;

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type StyledButtonProps = StyledButton & ButtonProps;

export const CopyButton: FC<StyledButtonProps> = ({
  children,
  style,
  ...props
}) => (
  <OutlinedButton
    {...props}
    style={{
      width: 'fit-content',
      padding: '0 20px',
      ...style,
    }}
    className='flex-center'
  >
    {children}
    <CopyIcon width={20} height={20} />
  </OutlinedButton>
);

const StyledBackButton = styled(BasicButton)`
  color: var(--white-40);
  background-color: transparent;
  border: none;
  width: fit-content;
  text-shadow: none;
  text-align: left;

  svg {
    margin-right: 12px;
    path {
      stroke: var(--white-40);
    }
  }
  :hover {
    color: #fff;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const BackButton: FC<ButtonProps> = ({ children, ...props }) => (
  <StyledBackButton {...props} className='flex-center' size='md'>
    <BackIcon width={32} height={32} />
    {children}
  </StyledBackButton>
);
