import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'helpers/constant';
import { useGetScoreSummaryWaitingData } from 'pages/hooks';

import { useAppSelector } from 'store';

import { PageLayout, PageNavBar } from 'components/Layout';
import { Display1, Heading2 } from 'components/Typography';

export const ScoreSummaryWaitingPage = () => {
  const navigate = useNavigate();
  const id = useAppSelector((store) => store.game.game.id);

  const isAllCurrentMatchupsFinished = useGetScoreSummaryWaitingData(id);

  useEffect(() => {
    isAllCurrentMatchupsFinished && navigate(PATH.scoreSummary);
  }, [isAllCurrentMatchupsFinished]);

  return (
    <PageLayout>
      <PageNavBar
        LeftContent={<Heading2>Matchup Concluded</Heading2>}
        MenuContent={<div />}
      />
      <div
        className='flex-between'
        style={{ justifyContent: 'center', height: '100%' }}
      >
        <Display1 style={{ textAlign: 'center' }}>
          Waiting for all
          <br />
          matchups to finish
        </Display1>
      </div>
    </PageLayout>
  );
};
