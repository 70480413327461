import { ResourceCategoryType, ResourceType } from 'store/resources';

import styled from 'styled-components';

import Resource from './resourceItem/Resource';

type PropsType = {
  category: ResourceCategoryType;
};

const Category: React.FC<PropsType> = ({ category }) => {
  return (
    <CategoryItem>
      <CategoryTitle>{category.name}</CategoryTitle>
      <ResourcesList>
        {category.resources.map((resource: ResourceType, index: number) => (
          <Resource
            key={resource.resourceId}
            resource={resource.resourceLink}
            index={index}
          />
        ))}
      </ResourcesList>
    </CategoryItem>
  );
};

export default Category;

const CategoryItem = styled.li`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  gap: 1.5rem;
`;

const CategoryTitle = styled.div`
  color: #fff;
  font-size: 1.25rem;
  line-height: normal;
  text-transform: uppercase;
`;

const ResourcesList = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;
