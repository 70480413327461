import { FC } from 'react';

import { usePlayoffBracket } from './hooks';

import { Heading2 } from 'components/Typography';

import {
  BracketProps,
  GridSpan,
  TwoTeamsChampionBracket,
} from './PlayoffBracket';

export const TwoTeamsBracket: FC<BracketProps> = ({ allowClick }) => {
  const { renderTeamsByRoom } = usePlayoffBracket(allowClick, 'large');
  return (
    <TwoTeamsChampionBracket>
      <GridSpan span={10} />

      <Heading2 color='primary' style={{ textAlign: 'center' }}>
        Championship
      </Heading2>
      <GridSpan span={1} />
      {renderTeamsByRoom(0, [])}
      <GridSpan />
      <GridSpan span={10} />
    </TwoTeamsChampionBracket>
  );
};
