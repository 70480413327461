import { roles } from 'components/assign-hosts-room/AssignHostsRoom';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Role = 'Host' | 'GameLeader' | 'coHost';
export const AdaptedRoles: { [key in roles]: Role } = {
  host: 'Host',
  coHost: 'coHost',
  gameLeader: 'GameLeader',
};

type AuthState = {
  token: string;
  refreshToken: string;
  gameId: string;
  role?: Role;
};

const initialState: AuthState = {
  token: '',
  refreshToken: '',
  gameId: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuth: (state, action: PayloadAction<AuthState>) => {
      return action.payload;
    },
    resetAuth: () => {
      return initialState;
    },
  },
});
export const { updateAuth, resetAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
