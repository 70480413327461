import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BoardSound from 'assets/sounds/board.mp3';
import CorrectSound from 'assets/sounds/ding.mp3';
import IntroSound from 'assets/sounds/intro.mp3';
import StrikeSound from 'assets/sounds/strike.mp3';

export const injectDragToScroll = (elementId: string) => {
  const container: any = document.querySelector(`#${elementId}`);
  if (!container) return;
  let startY;
  let startX;
  let scrollLeft;
  let scrollTop;
  let isDown;

  function mouseIsDown(e) {
    isDown = true;
    startY = e.pageY - container.offsetTop;
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
    scrollTop = container.scrollTop;
  }
  function mouseUp() {
    isDown = false;
  }
  function mouseLeave() {
    isDown = false;
  }
  function mouseMove(e) {
    if (isDown) {
      e.preventDefault();
      //Move vertcally
      const y = e.pageY - container.offsetTop;
      const walkY = y - startY;
      container.scrollTop = scrollTop - walkY;

      //Move Horizontally
      const x = e.pageX - container.offsetLeft;
      const walkX = x - startX;
      container.scrollLeft = scrollLeft - walkX;
    }
  }

  container.addEventListener('mousedown', (e) => mouseIsDown(e));
  container.addEventListener('mousemove', (e) => mouseMove(e));
  container.addEventListener('mouseup', mouseUp);
  container.addEventListener('mouseleave', mouseLeave);
};

export const getFullNumber = (num: number) =>
  Math.abs(num) < 10 ? `0${Math.abs(num)}` : Math.abs(num);

export const useCountDown = (minutes: number) => {
  const [timeCD, setTimeCd] = useState<number>(Math.round(minutes * 60));
  const minCD = getFullNumber(Math.floor(timeCD / 60));
  const secCD = getFullNumber(timeCD % 60);

  const startCountdown = (timeLeft: number) => {
    setTimeout(() => {
      if (timeLeft > 0) {
        setTimeCd(timeLeft - 1);
        startCountdown(timeLeft - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    const seconds = Math.round(minutes * 60);
    setTimeCd(seconds);
    startCountdown(seconds);
  }, [minutes]);

  return { minCD, secCD };
};

export const copyToClipboard = (text: string) => {
  const textField = document.createElement('textarea');
  textField.innerHTML = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();

  toast.success('Copied to clipboard');
};

type SoundType = 'strike' | 'correct' | 'intro' | 'answerIntro';
const sound = {
  strike: StrikeSound,
  correct: CorrectSound,
  intro: IntroSound,
  answerIntro: BoardSound,
};

export const usePlaySound = (type: SoundType, onEnded?: () => void) => {
  const audio = useMemo(() => new Audio(sound[type]), []);

  const play = useCallback(() => {
    const cloneNode: any = audio.cloneNode(true);
    cloneNode.play();
  }, []);

  useEffect(() => {
    audio.addEventListener('ended', () => {
      onEnded?.();
    });
    return () => {
      audio.removeEventListener('ended', () => {
        onEnded?.();
      });
    };
  }, []);

  return { play };
};
