export const getNameRound = (round: number, teamNumber: number) => {
  const team2 = ['FINALS', 'CHAMPION'];
  const team4 = ['SEMIFINALS', 'FINALS', 'CHAMPION'];
  const team8 = ['QUARTERFINALS', 'SEMIFINALS', 'FINALS', 'CHAMPION'];
  const team16 = [
    'EIGHTHFINALS',
    'QUARTERFINALS',
    'SEMIFINALS',
    'FINALS',
    'CHAMPION',
  ];
  switch (teamNumber) {
    case 2:
      return team2[round];
    case 4:
      return team4[round];
    case 8:
      return team8[round];
    case 16:
      return team16[round];
    default:
      break;
  }
  return '';
};
