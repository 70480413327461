import BackgroundImage from 'assets/images/login-background.png';

import styled from 'styled-components';

export const PageContainer = styled.div<{ firstLoad?: boolean }>`
  background-image: url(${BackgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  width: 100%;
  padding-top: 20vh;
  box-sizing: border-box;

  #logo {
    opacity: 0;
    opacity: ${({ firstLoad }) => (firstLoad ? 0 : 1)};
    width: 352px;
    height: auto;
    transform: ${({ firstLoad }) =>
      firstLoad ? 'translateY(145px)' : 'translateY(-60px)'};
    scale: ${({ firstLoad }) => (firstLoad ? 1 : 0.58)};
    transition: opacity 0.3s ease, transform 0.7s ease 1s, scale 0.7s ease 1s;
  }

  form > div,
  form > button {
    visibility: ${({ firstLoad }) => (firstLoad ? 'hidden' : 'visibility')};
    /* display: ${({ firstLoad }) => (firstLoad ? 'none' : 'block')}; */
    transform: ${({ firstLoad }) =>
      firstLoad ? 'translateY(0)' : 'translateY(-60px)'};
    transition: transform 0.3s, visibility 0.3s;
    transition-delay: 1.9s;
    width: 368px;
  }

  input {
    margin-bottom: 36px;
    width: 100%;
    box-sizing: border-box;
  }
`;
