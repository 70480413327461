import { FC } from 'react';

import { usePlayoffBracket } from './hooks';

import { Heading2, Heading3, Heading4 } from 'components/Typography';

import {
  BracketLine,
  BracketProps,
  EightTeamsChampionBracket,
  GridSpan,
} from './PlayoffBracket';

export const EightTeamsBracket: FC<BracketProps> = ({ allowClick }) => {
  const { renderTeamsByRoom } = usePlayoffBracket(allowClick);

  return (
    <>
      <div>
        <EightTeamsChampionBracket>
          <GridSpan span={16} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Quarterfinal
          </Heading3>
          <GridSpan />
          {renderTeamsByRoom(0, [4, 6])}
          <GridSpan span={6} />
          {renderTeamsByRoom(1, [4, 6])}

          <GridSpan span={4} />
          <BracketLine className='down' span={4} />
          <GridSpan span={2} />
          <BracketLine className='up' span={4} />
          <GridSpan span={2} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Semifinal
          </Heading3>
          <GridSpan span={6} />
          {renderTeamsByRoom(4, [8, 9])}
          <GridSpan span={5} />

          <GridSpan span={6} />
          <BracketLine className='up' span={3} />
          <BracketLine className='down' span={3} />
          <GridSpan span={4} />

          <Heading2 color='primary' style={{ textAlign: 'center' }}>
            Championship
          </Heading2>
          <GridSpan span={2} />
          <Heading4>1st Place Matchup</Heading4>
          {renderTeamsByRoom(8, [])}
          <GridSpan span={1} />
          <Heading4>3rd Place Matchup</Heading4>
          {renderTeamsByRoom(9, [])}
          <GridSpan span={2} />

          <GridSpan span={6} />
          <BracketLine className='down' span={3} />
          <BracketLine className='up' span={3} />
          <GridSpan span={4} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Semifinal
          </Heading3>
          <GridSpan span={6} />
          {renderTeamsByRoom(5, [8, 9])}
          <GridSpan span={5} />

          <GridSpan span={4} />
          <BracketLine className='up' span={4} />
          <GridSpan span={2} />
          <BracketLine className='down' span={4} />
          <GridSpan span={2} />

          <Heading3 color='primary' style={{ textAlign: 'center' }}>
            Quarterfinal
          </Heading3>
          <GridSpan />
          {renderTeamsByRoom(2, [5, 7])}
          <GridSpan span={6} />
          {renderTeamsByRoom(3, [5, 7])}

          <GridSpan span={16} />
        </EightTeamsChampionBracket>
      </div>

      <div>
        <EightTeamsChampionBracket>
          <GridSpan span={16} />
          <GridSpan span={16} />
          <GridSpan span={16} />

          <GridSpan span={6} />
          <GridSpan />
          {renderTeamsByRoom(6, [10, 11])}
          <GridSpan span={5} />

          <GridSpan span={6} />
          <BracketLine className='up' span={3} />
          <BracketLine className='down' span={3} />
          <GridSpan span={4} />

          <Heading2 color='primary' style={{ textAlign: 'center' }}>
            Consolation
          </Heading2>
          <GridSpan span={2} />
          <Heading4>5th Place Matchup</Heading4>
          {renderTeamsByRoom(10, [])}
          <GridSpan span={1} />
          <Heading4>7th Place Matchup</Heading4>
          {renderTeamsByRoom(11, [])}
          <GridSpan span={2} />

          <GridSpan span={6} />
          <BracketLine className='down' span={3} />
          <BracketLine className='up' span={3} />
          <GridSpan span={4} />

          <GridSpan span={6} />
          <GridSpan />
          {renderTeamsByRoom(7, [10, 11])}
          <GridSpan span={5} />

          <GridSpan span={16} />
          <GridSpan span={16} />
          <GridSpan span={16} />
        </EightTeamsChampionBracket>
      </div>
    </>
  );
};
