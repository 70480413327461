/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';

import { requestUrls } from 'helpers/constant';

import store from 'store';
import { AdaptedRoles, updateAuth } from 'store/authSlice';
import { closeLoading, openLoading } from 'store/loadingSlice';

import { roles } from 'components/assign-hosts-room/AssignHostsRoom';

import { Config } from '../config';
import { HostItem } from './default-settings';
import { GameInfo, updateUserActualStatus } from './game';

export interface GameHost {
  index: number;
  game: GameInfo;
  host: HostItem;
}

const baseUrl = Config.REACT_APP_LAUNCHPAD_BASE_URL;

export const ERROR_406 =
  'YOU WILL RECEIVE ACCESS 30 MINUTES BEFORE THE SCHEDULED GAME START TIME.';

export const loginGameViaCode = (
  loginData: {
    expectedRole: roles;
    eventId: string;
    email: string;
    code: string;
  },
  callback: () => void,
  onError: (message?: string) => void,
) => {
  const codeUrl = `${baseUrl}auth/signin/code`;

  openLoading();
  axios
    .post(codeUrl, loginData)
    .then(
      (
        res: AxiosResponse<{
          session: {
            accessToken: string;
            refreshToken: string;
            user: {
              metadata: { userId: string };
            };
          };
        }>,
      ) => {
        closeLoading();
        const {
          accessToken,
          refreshToken,
          user: {
            metadata: { userId },
          },
        } = res.data.session;
        store.dispatch(
          updateAuth({
            gameId: loginData.eventId,
            refreshToken,
            token: accessToken,
            role: AdaptedRoles[loginData.expectedRole],
          }),
        );
        callback();

        updateUserActualStatus({
          role: loginData.expectedRole,
          eventId: loginData.eventId,
          userId,
        });
      },
    )
    .catch((err) => {
      closeLoading();
      console.log('err', err);
      onError(
        err.data?.statusCode === 406
          ? ERROR_406
          : err.message || 'Please check your login code and try again.',
      );
    });
};

export const refreshAccessToken = async (authInfo) => {
  const url = `${baseUrl}${requestUrls.refreshToken}`;
  await axios
    .post(url, {
      refreshToken: authInfo.refreshToken,
    })
    .then((res) => {
      const { accessToken } = res.data;
      store.dispatch(
        updateAuth({
          ...authInfo,
          token: accessToken,
        }),
      );
    })
    .catch((e) => e);
};

export const verifyToken = async (
  accessToken: string,
  cb?: (data: any) => void,
) => {
  const url = `${baseUrl}${requestUrls.verifyToken}`;

  await axios
    .post(
      url,
      {},
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      cb?.(res.data);
      return res.data;
    })
    .catch((e) => e);
};
