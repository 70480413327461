import axios from 'axios';

import { requestUrls } from './constant';
import store from 'store';

import { Config } from '../config';
import { refreshAccessToken, verifyToken } from 'api/auth';

const API_URL = Config.REACT_APP_API_URL;

export type AppDispatch = typeof store.dispatch;

export default () => {
  axios.interceptors.request.use(async (config) => {
    const accessToken = store.getState().auth.token;
    return {
      ...config,
      // Avoid add API_URL prefix when using external http/https request
      url: `${config.url?.indexOf('http') !== 0 ? API_URL : ''}${config.url}`,
      headers: {
        Authorization: accessToken ? 'Bearer ' + accessToken : undefined,
        ...config.headers,
      },
    };
  });
  axios.interceptors.response.use(
    (res) => {
      if (res.data?.statusCode >= 400) {
        return Promise.reject({
          message: res.data?.message || 'Server error',
          data: res.data,
        });
      }

      return res;
    },
    async (err: any) => {
      const originalConfig = err.config;

      const {
        gameId,
        refreshToken,
        token: accessToken,
        role,
      } = store.getState().auth;

      if (originalConfig.url.includes(Config.REACT_APP_LAUNCHPAD_BASE_URL)) {
        if (
          (!originalConfig.url.includes(requestUrls.refreshToken) ||
            originalConfig.url.includes(requestUrls.verifyToken)) &&
          err.response
        ) {
          if (
            ((!originalConfig.url.includes(requestUrls.verifyToken) &&
              err.response.status === 401) ||
              (originalConfig.url.includes(requestUrls.storageUrl) &&
                err.response.status === 500)) &&
            !originalConfig._retry
          ) {
            originalConfig._retry = true;
            await verifyToken(accessToken);
            originalConfig.headers.Authorization = `Bearer ${
              store.getState().auth.token
            }`;
            const resp = await axios(originalConfig);
            return Promise.resolve(resp);
          }
          if (
            err.response.status === 401 &&
            originalConfig.url.includes(requestUrls.verifyToken) &&
            !originalConfig._retry
          ) {
            originalConfig._retry = true;
            await refreshAccessToken({ refreshToken, role, gameId });
            originalConfig.headers.Authorization = `Bearer ${accessToken}`;
          }
          return Promise.reject(err);
        }
      } else {
        return Promise.reject({
          message:
            err?.response?.data?.message || err?.message || 'Server error',
        });
      }
    },
  );
};
