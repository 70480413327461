import {
  CSSProperties,
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import OtpInput, { OTPInputProps } from 'react-otp-input';

import styled from 'styled-components';

import { Heading4, Heading5 } from './Typography';

export const Input = styled.input`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  height: 70px;
  border: 1px solid transparent;
  padding: 0 16px;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;

  width: 100%;
  box-sizing: border-box;

  :hover,
  :active {
    border-color: var(--primary);
  }

  ::placeholder {
    color: var(--white-40);
  }
`;

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  containerStyle?: CSSProperties;
};

const OtpInputContainer = styled.div`
  max-width: 100%;
  width: 408px;

  > div {
    max-width: 100%;

    > div {
      width: 25%;
    }
  }

  input {
    width: 100% !important;
    max-width: 100% !important;
    height: 118px;
    background: var(--black-20);
    border: 1px solid transparent;
    border-radius: 12px;
    margin: 0 4px;
    font-size: 40px;
    color: var(--white);
    margin-bottom: 36px;

    &:focus {
      border-color: var(--primary);
    }
  }
`;
export const CodeInput = (props: OTPInputProps) => {
  return (
    <OtpInputContainer>
      <OtpInput
        {...props}
        // isInputNum
        // focusStyle={{
        //   borderColor: 'var(--primary)',
        // }}
      />
    </OtpInputContainer>
  );
};

const InputGroupContainer = styled.div`
  p {
    text-transform: uppercase;
    margin-bottom: 8px;
  }
`;

export const InputGroup: FC<
  HTMLAttributes<HTMLDivElement> & {
    title: string;
    children?: React.ReactNode | React.ReactNode[];
    variant?: 'h4' | 'h5';
  }
> = ({ title, children, variant = 'h4', ...props }) => {
  const getHeadingType = () => {
    switch (variant) {
      case 'h4':
        return Heading4;
      case 'h5':
        return Heading5;
    }
  };

  const Heading = getHeadingType();
  return (
    <InputGroupContainer {...props}>
      <Heading>{title}</Heading>
      {children}
    </InputGroupContainer>
  );
};

interface TransparentInputProps extends InputProps {
  suffix?: string;
  containerStyle?: CSSProperties;
}

export const TransparentInput = forwardRef(
  (props: TransparentInputProps, ref: any) => {
    const { suffix, style, containerStyle, ...restProps } = props;
    return (
      <TransparentInputContainer style={containerStyle}>
        <input
          ref={ref}
          {...restProps}
          style={{
            border: 'none',
            outline: 'none',
            height: 35,
            textTransform: 'uppercase',
            backgroundColor: 'transparent',
            fontSize: 24,
            color: 'var(--primary)',
            fontFamily: 'fugaz-one',
            ...style,
          }}
        />
        {suffix && <TransparentInputSuffix>{suffix}</TransparentInputSuffix>}
      </TransparentInputContainer>
    );
  },
);

const TransparentInputContainer = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  border-bottom: 2px solid var(--primary);
`;

const TransparentInputSuffix = styled.span`
  display: table-cell;
  font-size: 24px;
  color: var(--primary);
  font-family: fugaz-one;
  text-transform: uppercase;
`;
