import { useMemo } from 'react';

import { ReactComponent as OpenLinkIcon } from 'assets/icons/open-link-icon.svg';

import { ResourceLinkType } from 'store/resources';

import styled from 'styled-components';

type PropsType = {
  resource: ResourceLinkType;
  index: number;
};

const Resource: React.FC<PropsType> = ({ resource, index }) => {
  const link = useMemo(
    () =>
      resource.url.startsWith('https://')
        ? resource.url
        : `https://${resource.url}`,
    [resource],
  );

  return (
    <ResourceWrap>
      <Index>{index + 1}</Index>
      <Name href={link} target='_blank' rel='noreferrer'>
        {resource.name}
      </Name>
      <Link href={link} target='_blank' rel='noreferrer'>
        <OpenLinkIcon />
      </Link>
    </ResourceWrap>
  );
};

export default Resource;

const ResourceWrap = styled.li`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  background: #1e2d54;
  box-sizing: border-box;
`;

const Index = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  line-height: 1;
  color: #fff;
  border-radius: 50%;
  background: #2d73cc;
  width: 2.5rem;
  height: 2.5rem;
`;

const Name = styled.a`
  flex-grow: 1;
  color: #fff;
  font-size: 1.25rem;
  text-decoration-line: underline;
  text-transform: uppercase;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg path {
    transition: fill 0.2 ease-in-out;
    fill: #fff;
  }

  &:hover svg path {
    // fill: #252636;
  }
`;
