import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import Background from 'assets/images/gameplay/congrat-bg.png';
import SparklingBackground from 'assets/images/gameplay/sparkling.png';
import ChampionText from 'assets/images/gameplay/the-champion.png';
import LogoImage from 'assets/images/logo.png';

import { copyToClipboard } from 'helpers/utils';
import { useGetCongratulationsData } from 'pages/hooks';

import { useAppSelector } from 'store';
import { AdaptedRoles } from 'store/authSlice';
import { EndGameStatus, GameTeam } from 'store/gameplaySlice';

import { ContainedButton, OutlinedButton } from 'components/Button';
import { Display1, Display2, Heading2, Heading3 } from 'components/Typography';
import { roles } from 'components/assign-hosts-room/AssignHostsRoom';
import styled from 'styled-components';

import { updateEndGameStatus } from 'api/game';
import { getGameRanking, getGameRankingForAdmin } from 'api/gameplay';

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  background-image: url(${Background});
  background-size: 101% 102%;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  > div {
    z-index: 1;
    flex-direction: column;
  }
`;
const ResultContainer = styled.div`
  height: 100vh;
  overflow: auto;
  padding: 65px 0;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 1120px;
  margin: 0 auto;
`;
const SparklingImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
const WinnerTeam = styled(Display1)`
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.33) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 12px 16px rgba(11, 31, 58, 0.5);
  font-size: 96px;
  margin-bottom: 32px;
  text-align: center;
`;
const TeamRanking = styled.div`
  width: 100%;
`;
const TopTeams = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  p:first-child {
    margin-bottom: 8px;
  }
`;
const RestTeamRanking = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CongratulationPage = () => {
  const role = useAppSelector((state) => state.auth.role);
  const gameId = useAppSelector((state) => state.game.game.id);
  const isGameLeader = useMemo(
    () => role === AdaptedRoles[roles.GAMELEADER],
    [role],
  );
  const showResult = useAppSelector(
    (state) => state.game.game.statusEnd === EndGameStatus.ranking,
  );

  const [teams, setTeams] = useState<GameTeam[]>([]);
  const secondGroups: GameTeam[] = useMemo(
    () =>
      teams.length === 16 ? [...teams].splice(1, 8) : [...teams].splice(1, 4),
    [teams],
  );
  const thirdGroups: GameTeam[] = useMemo(
    () =>
      teams.length === 16 ? [...teams].splice(9, 16) : [...teams].splice(5, 8),
    [teams],
  );
  const championMembers = useMemo(
    () => teams[0]?.members?.map((el) => el.name).join(', '),
    [teams],
  );

  useEffect(() => {
    const fetchRanking = isGameLeader ? getGameRankingForAdmin : getGameRanking;
    fetchRanking('', setTeams);
  }, []);

  useGetCongratulationsData(gameId);

  const onCopy = () => {
    copyToClipboard('Leave Us A Review!: https://bit.ly/review-the-feud');
  };

  const onContinue = () => {
    updateEndGameStatus(gameId, EndGameStatus.ranking);
  };

  if (teams.length === 0) {
    return null;
  }

  return (
    <Container>
      {showResult ? null : <SparklingImage src={SparklingBackground} />}
      {showResult ? (
        <ResultContainer>
          <img src={LogoImage} width={200} />
          <TeamRanking
            style={{
              display: 'flex',
              flexGrow: 1,
              height: 0,
              alignItems: 'center',
            }}
          >
            <TopTeams>
              <div style={{ textAlign: 'left' }}>
                <Heading2 color='primary'>champion</Heading2>
                <Display2>Team {teams[0].name}</Display2>
              </div>
            </TopTeams>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '50%',
                maxHeight: '100%',
                overflow: 'auto',
              }}
            >
              <RestTeamRanking>
                {secondGroups.map((_v, index) => (
                  <div key={index} style={{ marginBottom: 15 }}>
                    <Heading3 color='white-60' style={{ textAlign: 'left' }}>
                      #{index + 2}
                    </Heading3>
                    <Heading3 style={{ textAlign: 'left' }}>
                      Team {_v.name}
                    </Heading3>
                  </div>
                ))}
              </RestTeamRanking>
              <RestTeamRanking>
                {thirdGroups.map((_v, index) => (
                  <div key={index} style={{ marginBottom: 15 }}>
                    <Heading3 color='white-60' style={{ textAlign: 'left' }}>
                      #{index + (teams.length === 16 ? 10 : 6)}
                    </Heading3>
                    <Heading3 style={{ textAlign: 'left' }}>
                      Team {_v.name}
                    </Heading3>
                  </div>
                ))}
              </RestTeamRanking>
            </div>
          </TeamRanking>
          <div style={{ textAlign: 'left', width: 1120 }}>
            <OutlinedButton
              size='md'
              onClick={onCopy}
              className='flex-center'
              style={{ width: 50 }}
            >
              <LikeIcon style={{ margin: 0 }} />
            </OutlinedButton>
          </div>
        </ResultContainer>
      ) : (
        <div
          className='flex-center'
          style={{ height: '100vh', overflow: 'auto' }}
        >
          <img src={LogoImage} width={200} />
          <div style={{ margin: '7vh 0' }}>
            <img src={ChampionText} />
            <WinnerTeam>Team {teams[0].name}</WinnerTeam>
            <Heading2 style={{ textAlign: 'center' }}>
              {championMembers}
            </Heading2>
          </div>
          {isGameLeader && (
            <ContainedButton onClick={onContinue}>Continue</ContainedButton>
          )}
        </div>
      )}
    </Container>
  );
};
