import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as CloseIcon } from 'assets/icons/close-circle.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick-circle.svg';

import './Toast.css';

export const CustomToast = () => {
  return (
    <ToastContainer
      position='bottom-center'
      hideProgressBar
      autoClose={1000}
      pauseOnHover
      theme='colored'
      icon={({ type }) => {
        if (type === 'error') {
          return <CloseIcon width={20} height={20} />;
        }
        if (type === 'warning') {
          return <DangerIcon width={20} height={20} />;
        }
        return <TickIcon width={20} height={20} />;
      }}
      closeButton={() => (
        <CloseIcon
          width={16.67}
          height={16.67}
          style={{ alignSelf: 'center' }}
        />
      )}
    />
  );
};
