import uuidv4 from 'uuidv4';

import { useCallback, useRef, useState } from 'react';

import { ReactComponent as ChevronIcon } from 'assets/icons/arrow-down.svg';

import useOnClickOutside from 'hooks/useClickOutside';

import { SelectItemType } from 'components/Select';
import { ComponentWrap, HeaderBtn } from 'components/resources/Resources';
import styled from 'styled-components';

import Item from './item/Item';
import { updateInitialTeamsForAdmin } from 'api/game';

export const numberOfTeams = ['2', '4', '8', '16'];

export const availableNumberOfTeams: Array<SelectItemType> = numberOfTeams.map(
  (quantity) => ({
    label: `${quantity} TEAMS`,
    value: quantity,
  }),
);

type PropsType = {
  eventId: string;
  teamNumber: number;
};

const TeamsNumber: React.FC<PropsType> = ({ eventId, teamNumber }) => {
  const [isOpenResources, setIsOpenResources] = useState(false);
  const [currentTeamsNumber, setCurrentTeamsNumber] = useState(teamNumber);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setIsOpenResources(false);
  });

  const handleToggleResources = useCallback(
    () => setIsOpenResources((prev) => !prev),
    [],
  );

  const handleChangeTeamsNumber = useCallback(
    (newValue: string) => {
      if (currentTeamsNumber !== +newValue) {
        setCurrentTeamsNumber(+newValue);

        const rejectCallback = () => {
          setCurrentTeamsNumber(teamNumber);
        };

        const newTeams = Array.from({ length: +newValue }, (_, i) => ({
          id: uuidv4(),
          index: i,
          name: `Team ${i + 1}`,
        }));

        updateInitialTeamsForAdmin(eventId, newTeams, rejectCallback);
      }
      setIsOpenResources(false);
    },
    [currentTeamsNumber, eventId],
  );

  return (
    <ComponentWrap ref={ref}>
      <HeaderBtn
        type='button'
        onClick={handleToggleResources}
        isOpen={isOpenResources}
      >
        <span>CHANGE TEAMS</span>
        <ChevronIcon data-type='chevron' />
      </HeaderBtn>
      <Dropdown isOpen={isOpenResources}>
        <OptionsList>
          {availableNumberOfTeams.map((teamsNumber: SelectItemType) => (
            <Item
              key={teamsNumber.value}
              item={teamsNumber}
              onClickHandler={handleChangeTeamsNumber}
              currentValue={currentTeamsNumber}
            />
          ))}
        </OptionsList>
      </Dropdown>
    </ComponentWrap>
  );
};

export default TeamsNumber;

const Dropdown = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 0.5rem));
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.29);
  background: linear-gradient(180deg, #071b51 3.38%, #01030a 95.11%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.75rem;
`;

const OptionsList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  list-style: none;
  gap: 0.5rem;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;
