import axios from 'axios';

import { Config } from '../config';

export async function uploadFile(file: Blob | string): Promise<string> {
  const baseUrl = Config.REACT_APP_LAUNCHPAD_BASE_URL;

  const formData = new FormData();
  formData.append('file', file);

  const { data } = await axios.post(`${baseUrl}storage/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data.id;
}
