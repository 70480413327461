import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';

import { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import getCroppedImg, { getFileFromURL } from 'helpers/crop-image';

import styled from 'styled-components';

export const AvatarInput = styled.div`
  position: relative;
  background-color: var(--white-10);
  border-radius: 12px;
  margin: 12px auto 32px auto;
  justify-content: center;
  background-size: 100% 100%;
`;

interface Props {
  url?: string;
  onChangeFile?: (url: string) => void;
  cropWidth?: number;
  cropHeight?: number;
  avatarWidth?: number;
  avatarHeight?: number;
  setOriginalImage?: (url: string) => void;
}

export const CropImage = forwardRef(
  (
    {
      url,
      onChangeFile,
      cropWidth = 256,
      cropHeight = 256,
      avatarWidth = 256,
      avatarHeight = 256,
      setOriginalImage,
    }: Props,
    ref: any,
  ) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [file, setFile] = useState<any>(null);
    const [src, setSrc] = useState('');

    useEffect(() => {
      if (file) {
        const fileURL = URL.createObjectURL(file);
        onChangeFile?.(fileURL);
        setSrc(fileURL);
        return;
      }
    }, [file]);

    const getCropImageFile = useCallback(async () => {
      try {
        const croppedImage: string = await getCroppedImg(
          src,
          croppedAreaPixels,
          0,
        );
        return await getFileFromURL(croppedImage, file?.name, file?.type);
      } catch (e) {
        toast.error('Crop host avatar failed');
      }
    }, [file, src, croppedAreaPixels]);

    useImperativeHandle(
      ref,
      () => ({
        getFile: async () => getCropImageFile(),
      }),
      [getCropImageFile],
    );

    useEffect(() => {
      if (url) {
        setSrc(url);
        onChangeFile(url);
      }
    }, [url]);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleChangeFile = (e) => {
      setFile(e.target.files[0]);
      setOriginalImage(e.target.files[0]);
    };

    const onRemoveFile = () => {
      setFile(null);
      setSrc('');
      onChangeFile?.('');
    };

    const onZoomChange = (value) => {
      setZoom(value);
      if (value !== 1) {
        onChangeFile(`${src}?zoome=${value}`);
      } else {
        onChangeFile(src);
      }
    };

    return (
      <div
        style={{
          position: 'relative',
          marginTop: 12,
          marginBottom: 32,
        }}
      >
        {src ? (
          <Cropper
            image={src}
            crop={crop}
            zoom={zoom}
            cropSize={{
              width: cropWidth,
              height: cropHeight,
            }}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            style={{
              containerStyle: {
                position: 'relative',
                height: cropHeight,
                width: cropWidth,
              },
              mediaStyle: { height: cropHeight },
            }}
            objectFit='vertical-cover'
          />
        ) : (
          <div>
            <label
              htmlFor='avatar'
              style={{
                display: 'block',
                width: 'fit-content',
                margin: '0 auto',
              }}
            >
              <AvatarInput
                style={{ height: avatarHeight, width: avatarWidth }}
                className='flex-between'
              >
                <AvatarIcon />
                <CameraIcon
                  style={{ position: 'absolute', right: 13, bottom: 17 }}
                />
              </AvatarInput>
            </label>
            <input
              type='file'
              style={{ display: 'none' }}
              id='avatar'
              onChange={handleChangeFile}
              accept='image/*'
            />
          </div>
        )}
        {src && (
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              borderRadius: '50%',
              width: 20,
              height: 20,
              padding: 2,
              backgroundColor: 'var(--danger)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={onRemoveFile}
          >
            <CloseIcon width={16} height={16} />
          </div>
        )}
      </div>
    );
  },
);
