import { Config } from 'config';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Background from 'assets/images/gameplay/main-stage-bg.png';

import { useGetMainStageData } from 'pages/hooks';

import { useAppSelector } from 'store';

import { ContainedButton } from 'components/Button';
import styled from 'styled-components';

import { TeamView } from './TeamView';
import { getDetailGameForHost } from 'api/game';
import { getGamePlayingInfo, startGameRound } from 'api/gameplay';

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
`;

const StartRoundButton = styled(ContainedButton)`
  position: fixed;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
`;

export const MainStagePage = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const teamA = useAppSelector((state) => state.gameplay.teamA);
  const teamB = useAppSelector((state) => state.gameplay.teamB);
  const themeBgId = useAppSelector((state) => state.gameplay.game.theme.value);
  const gameId = useAppSelector((state) => state.game.game.id);
  const room = useAppSelector((state) => state.game.gameRoom);
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [isGameInfoLoaded, setIsGameInfoLoaded] = useState(false);
  const isOnlyViewAccess = useMemo(() => !!(location.state as any)?.host, []);
  const [submitLoading, setSubmitLoading] = useState(false);

  const themeBackgroundUrl = useMemo(
    () => `${Config.REACT_APP_LAUNCHPAD_BASE_URL}storage/files/${themeBgId}`,
    [themeBgId],
  );

  useEffect(() => {
    getDetailGameForHost(
      { eventId: gameId },
      () => {
        setIsGameInfoLoaded(true);
      },
      () => navigate(`/game/${gameId}/panel`),
    );
  }, []);

  useEffect(() => {
    //check if prev use effect request is finished
    isGameInfoLoaded &&
      room?.id &&
      getGamePlayingInfo(
        {
          eventId: gameId,
          roomId,
          isStart: 0,
        },
        (data: any) => {
          if (data.rounds.length) {
            return navigate(`/game/gameplay/${roomId}`);
          }
          setLoaded(true);
        },
        () => {
          setError(true);
        },
      );
  }, [gameId, room?.id, isGameInfoLoaded]);

  useGetMainStageData(isGameInfoLoaded, roomId, setLoaded);

  const handleStartRound = useCallback(async () => {
    setSubmitLoading(true);
    !submitLoading &&
      (await startGameRound(
        {
          gameId,
          roomId,
        },
        () => navigate(`/game/gameplay/${roomId}`),
      ));
    setSubmitLoading(false);
  }, [submitLoading, gameId, roomId]);

  return (
    <Container
      style={{
        backgroundImage: loaded
          ? `url(${themeBackgroundUrl || Background})`
          : undefined,
      }}
    >
      <TeamView team={teamA} position='left' teamPlay={0}>
        {isOnlyViewAccess && (
          <ContainedButton
            size='md'
            containedColor='danger'
            onClick={() => navigate(`/game/${gameId}/panel`)}
            style={{ position: 'absolute', right: -254, top: 10 }}
          >
            Exit
          </ContainedButton>
        )}
      </TeamView>
      <TeamView team={teamB} position='right' teamPlay={1} />

      <StartRoundButton
        containedColor='success'
        size='md'
        onClick={handleStartRound}
        disabled={isOnlyViewAccess || error || !loaded || submitLoading}
        // disabled={room?.id !== +roomId}
      >
        Start Round 1
      </StartRoundButton>
    </Container>
  );
};
