import axios, { AxiosResponse } from 'axios';

import { toast } from 'react-toastify';

import { closeLoading, openLoading } from 'store/loadingSlice';

interface CreateCountDownTime {
  createdAt: string;
}

export const createCountDownTime = (
  body: {
    type: string;
    gameId: string;
  },
  callback?: (data: CreateCountDownTime) => void,
) => {
  axios
    .post('/api/game-times', body)
    .then((res: AxiosResponse<{ data: any }>) => callback?.(res.data.data))
    .catch((err) => {
      console.log('createCountDownTime err', err);
    });
};

export const getEventCountDownTime = (
  body: {
    type: string;
    gameId: string;
  },
  callback: (data: CreateCountDownTime | null) => void,
) => {
  axios
    .get('/api/game-times', {
      params: {
        gameId: body.gameId,
        type: body.type,
      },
    })
    .then((res: AxiosResponse<{ data: CreateCountDownTime | null }>) =>
      callback?.(res.data.data),
    )
    .catch((err) => {
      console.log('createCountDownTime err', err);
    });
};

export const updateBreakoutGameStatus = (id: string, callback: () => void) => {
  openLoading();
  axios
    .patch(`/api/games/${id}/breakout`, {
      isBreakout: 1,
    })
    .then(() => {
      closeLoading();
      callback();
    })
    .catch((err) => {
      closeLoading();
      toast.error('Process to tournament failed');
      console.log('updateBreakoutGameStatus err', err);
    });
};

export const updateBreakoutPopupStatus = (id: string, callback: () => void) => {
  openLoading();
  axios
    .patch(`/api/games/${id}/breakout-popup`, {
      isBreakoutPopup: 1,
    })
    .then(() => {
      closeLoading();
      callback();
    })
    .catch((err) => {
      closeLoading();
      toast.error('Open breakout room failed');
      console.log('updateBreakoutPopupStatus err', err);
    });
};
