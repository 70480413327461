import { persistReducer, persistStore } from 'redux-persist';

import * as Sentry from '@sentry/react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import {
  playoffBracketReducer,
  playoffBracketResultReducer,
} from 'components/playoff-bracket/playoffBracketSlice';

import { Config } from '../config';
import { authReducer } from './authSlice';
import { gameReducer } from './gameSlice';
import { gameplayReducer } from './gameplaySlice';
import { loadingReducer } from './loadingSlice';
import { resourcesReducer } from './resources';
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const reducers = combineReducers({
  auth: authReducer,
  game: gameReducer,
  gameplay: gameplayReducer,
  loading: loadingReducer,
  playoffBracket: playoffBracketReducer,
  playoffBracketResult: playoffBracketResultReducer,
  resources: resourcesReducer,
});

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['auth', 'game', 'playoffBracketResult'],
};

const persistedReducers = persistReducer(persistConfig, reducers);

const enhanceWithResetReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = {};
  }
  return persistedReducers(state, action);
};

const store = configureStore({
  reducer: enhanceWithResetReducer,
  devTools: Config.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer],
});

export const persistor = persistStore(store);

// State Type
export type RootState = ReturnType<typeof reducers>;

// Inject Type RootState on useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export const resetAllState = () => {
  store.dispatch({ type: 'RESET' });
  persistor.flush();
  persistor.purge();
  persistor.persist();
};
