import { Outlet } from 'react-router-dom';

import LoadingIcon from 'assets/images/loading.gif';

import { useAppSelector } from 'store';

export const GlobalLayout = () => {
  const isLoading = useAppSelector((state) => state.loading.isLoading);

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'var(--white-10)',
            justifyContent: 'center',
            zIndex: 999,
          }}
          className='flex-between'
        >
          <img
            src={LoadingIcon}
            alt='Loading'
            style={{ width: 60, height: 60 }}
          />
        </div>
      )}
      <Outlet />
    </>
  );
};
