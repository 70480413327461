interface CommonData {
  prevItemId: string | null;
  nextItemId: string | null;
}

export const getSortedData = <T extends CommonData>(
  entitiesArray: Array<T>,
  key: string,
): Array<T> => {
  const objectMap = new Map();
  let firstObject;
  const sortedArray = [];
  for (const obj of entitiesArray) {
    objectMap.set(obj[key], obj);
    if (!obj.prevItemId) {
      firstObject = obj;
    }
  }
  for (
    let start = firstObject;
    start;
    start = objectMap.get(start.nextItemId)
  ) {
    sortedArray.push(start);
  }
  return [...sortedArray];
};
