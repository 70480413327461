import axios, { AxiosResponse } from 'axios';

import { toast } from 'react-toastify';

import store from 'store';
import {
  GamePlaying,
  GameRoundInfo,
  GameTeam,
  RoundStatus,
  TeamPlaying,
  updateGamePlaying,
  updateGamePlayingAfterAnswerReveal,
} from 'store/gameplaySlice';
import { closeLoading, openLoading } from 'store/loadingSlice';

import { revealWinnersResult } from 'components/playoff-bracket/playoffBracketSlice';

export const getGamePlayingInfo = async (
  {
    eventId,
    roomId,
    isStart = 1,
  }: { eventId: string; roomId: string; isStart?: number },
  callback?: (data: GamePlaying) => void,
  onError?: () => void,
) => {
  await axios
    .get(`/api/gameplay/room-playing`, {
      params: { eventId, roomId, isStart },
    })
    .then((res: AxiosResponse<{ data: GamePlaying }>) => {
      store.dispatch(updateGamePlaying(res.data.data));
      callback?.(res.data.data);
    })
    .catch((err) => {
      console.log('getGamePlayingInfo err', err);
      onError?.();
    });
};

export const getStartedRoom = (
  { eventId, roomId }: { eventId: string; roomId: string },
  callback?: (data: GamePlaying) => void,
  onError?: () => void,
) => {
  axios
    .get(`/api/gameplay/started-room-playing`, { params: { eventId, roomId } })
    .then((res: AxiosResponse<{ data: GamePlaying }>) => {
      store.dispatch(updateGamePlaying(res.data.data));
      callback?.(res.data.data);
    })
    .catch((err) => {
      console.log('getStartedGamePlayingInfo err', err);
      onError?.();
    });
};

export const getGamePlayingInfoForAdmin = (
  gameId: string,
  roomId: string,
  callback?: (data: GamePlaying) => void,
  onError?: () => void,
) => {
  axios
    .get(`/api/admin/gameplay/${gameId}/${roomId}/room-playing`)
    .then((res: AxiosResponse<{ data: GamePlaying }>) => {
      store.dispatch(updateGamePlaying(res.data.data));
      callback?.(res.data.data);
    })
    .catch((err) => {
      console.log('getGamePlayingInfoForAdmin err', err);
      onError?.();
    });
};

export const startGameRound = async (
  { gameId, roomId }: { gameId: string; roomId: string },
  callback: () => void,
) => {
  openLoading();
  await axios
    .post(`/api/gameplay/start-round`, { gameId, roomId })
    .then(() => {
      callback();
    })
    .catch((err) => {
      console.log('startGameRound err', err);
      toast.error('Start round failed!');
    })
    .finally(() => closeLoading());
};

const getGameRoundId = () => {
  return store.getState().gameplay.rounds[
    store.getState().gameplay.rounds.length - 1
  ]?.id;
};

export const getGameRoundInfo = async () => {
  const roundId = getGameRoundId();
  if (!roundId) {
    console.log('getGameRoundInfo err');
    return;
  }
  await axios
    .get(`/api/game-room-rounds/${roundId}`)
    .then((res: AxiosResponse<{ data: GameRoundInfo }>) => {
      store.dispatch(
        updateGamePlaying({
          curRound: res.data.data,
        }),
      );
    })
    .catch((err) => {
      console.log('getGameRoundInfo err', err);
      toast.error('Get game round info failed!');
    });
};

export const revealQuestionAnswer = (
  { answerId, point }: { answerId: string; point: number },

  allowFlipping: () => void,
) => {
  const roundId = getGameRoundId();
  if (!roundId) {
    console.log('revealQuestionAnswer err');
    return;
  }
  axios
    .patch(`/api/game-room-rounds/${roundId}/answer`, {
      answerId,
      answerPoint: point,
    })
    .then(() => {
      store.dispatch(updateGamePlayingAfterAnswerReveal({ answerId, point }));
    })
    .catch((err) => {
      console.log('revealQuestionAnswer err', err);
      toast.error('Update point for team failed!');
    })
    .finally(() => {
      allowFlipping();
    });
};

export const strikeForTeam = (strikes: number, callback: () => void) => {
  const roundId = getGameRoundId();
  if (!roundId) {
    console.log('stikeForATeam err');
    return;
  }

  axios
    .patch(`/api/game-room-rounds/${roundId}/strike`, {
      strikes,
    })
    .then(() => {
      getGameRoundInfo();
      callback();
    })
    .catch((err) => {
      console.log('stikeForATeam err', err);
      toast.error('Strike for team failed!');
    });
};

export const throwOutQuestion = (callback: () => void) => {
  const roundId = getGameRoundId();
  if (!roundId) {
    console.log('throwOutQuestion err');
    return;
  }
  axios
    .patch(`/api/game-room-rounds/${roundId}/throw-out-question`)
    .then(() => {
      callback();
      getGameRoundInfo();
    })
    .catch((err) => {
      console.log('throwOutQuestion err', err);
      toast.error('Throw out question failed!');
    });
};

export const addRoundPointToTeam = ({
  gameId,
  roomId,
  teamPlay,
  pointsMultiplier,
}: {
  gameId: string;
  roomId: string;
  teamPlay: TeamPlaying;
  pointsMultiplier?: number;
}) => {
  const roundId = getGameRoundId();

  if (!roundId) {
    console.log('addRoundPointToTeam err');
    return;
  }

  const requestObject = pointsMultiplier
    ? {
        teamPlay,
        pointsMultiplier,
      }
    : {
        teamPlay,
      };

  axios
    .patch(`/api/game-room-rounds/${roundId}/point`, requestObject)
    .then(() => {
      getGamePlayingInfo({ eventId: gameId, roomId });
      // toast.success('Add round point to team successfully');
    })
    .catch((err) => {
      console.log('addRoundPointToTeam err', err);
      toast.error('Add round point to team failed!');
    });
};

export const updateRoundStatusPlaying = ({
  gameId,
  roomId,
}: {
  gameId: string;
  roomId: string;
}) => {
  const roundId = getGameRoundId();

  if (!roundId) {
    console.log('updateRoundStatusPlaying err');
    return;
  }

  axios
    .patch(`/api/game-room-rounds/${roundId}/status`, {
      status: RoundStatus.playing,
    })
    .then(() => {
      getGamePlayingInfo({ eventId: gameId, roomId });
    })
    .catch((err) => {
      console.log('updateRoundStatusPlaying err', err);
      toast.error('update round status failed!');
    });
};

export const editRoundPointForTeam = (
  gameId: string,
  teamPlay: TeamPlaying,
  point: number,
  callback?: () => void,
) => {
  const roomId = store.getState().gameplay.id;

  if (!roomId) {
    console.log('editRoundPointForTeam err');
    return;
  }

  const key = teamPlay === 0 ? 'pointA' : 'pointB';

  axios
    .patch(`/api/game-rooms/${roomId}/point`, {
      [key]: point,
    })
    .then(() => {
      getGamePlayingInfo({ eventId: gameId, roomId });
      callback?.();
      // toast.success('Add round point to team successfully');
    })
    .catch((err) => {
      console.log('editRoundPointForTeam err', err);
      toast.error('Edit round point failed!');
    });
};

export const finishGameRoom = async (
  { roomId }: { roomId: string },
  callback: () => void,
) => {
  openLoading();
  const roundId = getGameRoundId();
  if (!roundId) {
    console.log('finishGameRoom err');
  } else {
    await axios
      .post(`/api/gameplay/scoring-summary`, { roomId })
      .then(() => {
        callback();
      })
      .catch((err) => {
        console.log('finishGameRoom err', err);
        toast.error('Finish game room failed!');
      });
  }
  closeLoading();
};

export const getWaitingMatchUpInfo = (callback: (done: boolean) => void) => {
  const gameId = store.getState().game.game.id;
  if (!gameId) {
    console.log('getWaitingMatchUpInfo err');
    return;
  }

  const isConsolation = store.getState().gameplay.isConsolation;
  axios
    .get(`/api/gameplay/${gameId}/waiting-matchups`, {
      params: {
        isConsolation,
      },
    })
    .then((res: AxiosResponse<{ statusCode: number }>) => {
      callback(res.data.statusCode === 200);
    })
    .catch((err) => {
      console.log('getWaitingMatchUpInfo err', err);
    });
};

export const getAllMatchups = (
  id: string,
  role: string,
  callback?: ({
    rooms,
    statusEnd,
  }: {
    rooms: GamePlaying[];
    statusEnd: number;
  }) => void,
) => {
  axios
    .get(`/api/admin/gameplay/${id}/rooms`, {
      params: {
        role,
        page: 1,
        limit: 999,
      },
    })
    .then(
      (
        res: AxiosResponse<{
          data: { rooms: GamePlaying[]; statusEnd: number };
        }>,
      ) => {
        callback?.(res.data.data);
      },
    )
    .catch((err) => {
      console.log('getAllMatchups err', err);
    });
};

export const getGameRanking = (
  id: string,
  callback: (teams: GameTeam[]) => void,
) => {
  const gameId = id || store.getState().game.game.id;
  if (!gameId) {
    console.log('getGameRanking err');
    return;
  }

  axios
    .get(`/api/gameplay/${gameId}/ranking`)
    .then((res: AxiosResponse<{ data: GameTeam[] }>) => {
      callback(res.data.data);
    })
    .catch((err) => {
      console.log('getGameRanking err', err);
    });
};

export const getGameRankingForAdmin = (
  id: string,
  callback: (teams: GameTeam[]) => void,
) => {
  const gameId = id || store.getState().game.game.id;
  if (!gameId) {
    console.log('getGameRankingForAdmin err');
    return;
  }

  axios
    .get(`/api/admin/gameplay/${gameId}/ranking`)
    .then((res: AxiosResponse<{ data: GameTeam[] }>) => {
      callback(res.data.data);
    })
    .catch((err) => {
      console.log('getGameRankingForAdmin err', err);
    });
};

export const startNextMatchUpRound = () => {
  const gameId = store.getState().game.game.id;
  if (!gameId) {
    console.log('startNextMatchUpRound err');
    return;
  }

  axios
    .post(`/api/admin/gameplay/${gameId}/next-matchup`)
    .then(() => {
      toast.success('Started next round');
      getGameRoundInfo();
    })
    .catch((err) => {
      console.log('startNextMatchUpRound err', err);
      toast.error('Start next round failed!');
    });
};

export const revealMatchups = (gameId: string, matches: Array<GamePlaying>) => {
  const round = matches[0] ? matches[0].round : -1;

  const matchupsIds = matches.map((el) => el.index);
  axios
    .patch(`/api/game-rooms/reveal`, {
      gameId,
      round,
    })
    .then(() => {
      store.dispatch(revealWinnersResult(matchupsIds));
    })
    .catch((err) => {
      console.log('reveal matchups err', err);
    });
};
